// Modal/styled.js

import styled from "styled-components";
import { colors } from "../../../utils/colors";

export const ModalContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
`;

export const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 400px;
  background-color: ${colors["login-background"]};
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  border-radius: 16px;
  padding: 24px;
  position: relative;
`;

export const CloseButton = styled.button`
  position: absolute;
  top: 16px;
  right: 16px;
  background-color: transparent;
  border: none;
  cursor: pointer;
  color: ${colors.colo1};
  font-size: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ModalTitle = styled.h2`
  margin: 0;
  font-size: 24px;
  font-weight: bold;
  color: ${colors.colo1};
  margin-bottom: 16px;
  text-align: center;
  line-height: 1.5;
`;

export const ModalText = styled.p`
  margin: 0;
  font-size: 16px;
  color: #333;
  text-align: center;
  line-height: 1.5;
  margin-bottom: 24px;
`;
