import React, { useEffect, useState } from 'react';
import { BasicButton } from '../../Button';
import { Input } from '../../Input';
import { ButtonsContainer, InputListContainer, InputLabelContainer, Label } from './styled';
import { useDispatch, useSelector } from 'react-redux';
import { changePassword } from '../../../../store/Users/actions';
import { isAdmin } from '../../../../store/Login/selectors';
import ChangePasswordModal from './Components/ChangePasswordModal';


const UserDetail = ({ userData, goBackAction, saveAction }) => {

  const [id, setUserId] = useState(userData?.id || '');
  const [username, setUsername] = useState(userData?.username || '');
/*   const [lastname, setLastname] = useState(userData?.lastname || '');
 */  const [name, setName] = useState(userData?.name || '');
  const [email, setEmail] = useState(userData?.email || '');
  const [dni, setDni] = useState(userData?.dni || '');
  const [phone, setPhone] = useState(userData?.phone || '');
  const [birthdate, setBirthdate] = useState(userData?.birthdate || '');
  const [medicare, setMedicare] = useState(userData?.medicare || '');

  const [showChangePasswordModal, setShowChangePasswordModal] = useState(false);

  const userLoggedAdmin = useSelector(isAdmin)

  const dispatch = useDispatch();

  useEffect(() => {
    if (userData) {
      setUserId(userData.id || '');
      setUsername(userData.username || '');
      setName(userData.name || '');
      setEmail(userData.email || '');
      setDni(userData.dni || '');
      setPhone(userData.phone || '');
      setBirthdate(userData.birthdate || '');
/*       setLastname(userData.lastname || '');
 */   setMedicare(userData.medicare || '');
    }
  }, [dispatch]);

  if (!userData) {
    return null;
  }

  const handleChange = (event) => {
    const eName = event.target.name;
    const eValue = event.target.value;
    switch (eName) {
      case "name":
        setName(eValue);
        break;
      case "email":
        setEmail(eValue);
        break;
      case "dni":
        setDni(eValue);
        break;
      case "phone":
        setPhone(eValue);
        break;
      case "birthdate":
        setBirthdate(eValue);
        break;
/*       case "lastname":
        setLastname(eValue);
        break; */
      case "medicare":
        setMedicare(eValue);
        break;
      default:
        break;
    }
  }

  const saveButtonAction = () => {
    const auxUser = {
      id,
      username,
      name,
      email,
      dni,
      phone,
      birthdate,
/*       lastname,
 */      medicare,
    }
    saveAction(auxUser);
  }

  const handleChangePassword = (oldPassword, newPassword) => {
    if ((userLoggedAdmin && !newPassword) || (!userLoggedAdmin && (!oldPassword || !newPassword))) {
      return;
    }
    dispatch(changePassword(userData.username, userLoggedAdmin, newPassword, oldPassword))
    setShowChangePasswordModal(false);
  }

  return (
    <div style={{ width: '100%' }}>
      <InputListContainer>
        <InputLabelContainer>
          <Label>Usuario</Label>
          <Input name={"username"} label="Usuario" value={username} readOnly={true} />
        </InputLabelContainer>
        {/* <InputLabelContainer>
          <Label>Apellido</Label>
          <Input onChange={handleChange} name={"lastname"} label="Apellido" value={lastname} />
        </InputLabelContainer> */}
        <InputLabelContainer>
          <Label>Nombre</Label>
          <Input onChange={handleChange} name={"name"} label="Nombre" value={name} />
        </InputLabelContainer>
        <InputLabelContainer>
          <Label>Email</Label>
          <Input onChange={handleChange} name={"email"} label="Email" value={email} />
        </InputLabelContainer>
        <InputLabelContainer>
          <Label>DNI</Label>
          <Input onChange={handleChange} name={"dni"} type={"number"} label="DNI" value={dni} />
        </InputLabelContainer>
        <InputLabelContainer>
          <Label>Obra social</Label>
          <Input onChange={handleChange} name={"medicare"} label="ObraSocial" value={medicare} />
        </InputLabelContainer>
        <InputLabelContainer>
          <Label>Teléfono</Label>
          <Input onChange={handleChange} name={"phone"} label="Teléfono" value={phone} />
        </InputLabelContainer>
        <InputLabelContainer>
          <Label>Fecha de nacimiento</Label>
          <Input onChange={handleChange} name={"birthdate"} type={"date"} label="Edad" value={birthdate} />
        </InputLabelContainer>
        {showChangePasswordModal && <ChangePasswordModal
          isOpen={showChangePasswordModal}
          onAccept={handleChangePassword}
          onClose={() => setShowChangePasswordModal(false)}
          showOldPasswordInput={!userLoggedAdmin} />}
        <div>
          <BasicButton onClick={() => setShowChangePasswordModal(true)}>Resetear contraseña</BasicButton>
        </div>
      </InputListContainer>
      <ButtonsContainer>
        <BasicButton onClick={goBackAction}>Cancelar</BasicButton>
        <BasicButton onClick={saveButtonAction}>Guardar</BasicButton>
      </ButtonsContainer>
    </div>
  );
};

export default UserDetail;
