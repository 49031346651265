import React, { useEffect, useState } from "react";
import { SidebarWrapper, ToggleButton, GroupTitle, ItemContainer, LogoutContainer, Container, Title, ButtonContainer, Button, ProfileButton, Logo, LogoContainer, DropdownContainer, DropdownList, DropdownItem, DropdownLink } from "./styled";
import logo from '../../../images/gin-cast-logo.png'
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../../store/Login/actions";
import { isLoggedIn, isAdmin } from "../../../store/Login/selectors";
import { useNavigate } from 'react-router-dom';
import { setPageTitle } from '../../../store/General/actions';
//import UserIcon from "../UserIcon";
//import { colors } from "../../../utils/colors";

const Header = () => {
  const [isProfileOpen, setIsProfileOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const loggedIn = useSelector(isLoggedIn)
  const isAdministrator = useSelector(isAdmin)

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (!loggedIn){
      setIsProfileOpen(false);
    } 
  }, [loggedIn])


  if (! loggedIn) {
    return null
  }

  const goHome = () => {
    navigate('/home', { replace: true });
    dispatch(setPageTitle('Mis Analisis'));
  }

  const goProfile = () => {
    navigate('/profile', { replace: true });
    dispatch(setPageTitle('Perfil'));
  }

  const goToAllStudies = () => {
    navigate('/studies', { replace: true });
    dispatch(setPageTitle('Analisis'));
  }

  const goToUsers = () => {
    navigate('/users', { replace: true });
    dispatch(setPageTitle('Usuarios'));
  }

  const goToPreLoads = () => {
    navigate('/preload', { replace: true });
    dispatch(setPageTitle('Estudios Pre cargados'));
  }

  const goToLogout = () => {
    dispatch(logout());
  }

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div style={{flexGrow: '0'}}>
      <SidebarWrapper isOpen={isOpen}>
        <Container isOpen={isOpen}>
          <LogoContainer onClick={goHome}>
            <Logo isOpen={isOpen} src={logo} />
            <Title isOpen={isOpen}>GinCast</Title>
            <ToggleButton isOpen={isOpen} onClick={toggleSidebar}>
            {isOpen ? 
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path d="M12.727 20.313C12.8172 20.4085 12.8877 20.5208 12.9346 20.6435C12.9814 20.7662 13.0036 20.897 12.9998 21.0283C12.9961 21.1596 12.9666 21.2889 12.9129 21.4088C12.8592 21.5286 12.7825 21.6368 12.687 21.727C12.5915 21.8172 12.4792 21.8878 12.3565 21.9346C12.2338 21.9814 12.103 22.0036 11.9717 21.9999C11.8404 21.9961 11.7111 21.9666 11.5912 21.9129C11.4714 21.8592 11.3632 21.7825 11.273 21.687L2.773 12.687C2.59746 12.5013 2.49965 12.2555 2.49965 12C2.49965 11.7445 2.59746 11.4987 2.773 11.313L11.273 2.312C11.3626 2.21444 11.4707 2.13566 11.5911 2.08023C11.7114 2.02481 11.8415 1.99385 11.9739 1.98914C12.1063 1.98444 12.2383 2.00609 12.3623 2.05284C12.4862 2.09958 12.5997 2.17049 12.696 2.26144C12.7923 2.3524 12.8696 2.46159 12.9234 2.58266C12.9771 2.70374 13.0063 2.83429 13.0092 2.96674C13.0121 3.09919 12.9886 3.23089 12.9402 3.35419C12.8917 3.47749 12.8193 3.58994 12.727 3.685L4.875 12L12.727 20.313Z" fill="black"/>
              </svg>
             : 
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path d="M11.273 3.687C11.1828 3.59153 11.1123 3.47922 11.0654 3.35649C11.0186 3.23376 10.9964 3.10302 11.0002 2.97171C11.0039 2.84041 11.0334 2.71113 11.0871 2.59124C11.1408 2.47135 11.2175 2.36322 11.313 2.273C11.4085 2.18278 11.5208 2.11225 11.6435 2.06543C11.7662 2.01862 11.897 1.99643 12.0283 2.00015C12.1596 2.00386 12.2889 2.0334 12.4088 2.08708C12.5286 2.14076 12.6368 2.21753 12.727 2.313L21.227 11.313C21.4025 11.4987 21.5003 11.7445 21.5003 12C21.5003 12.2555 21.4025 12.5013 21.227 12.687L12.727 21.688C12.6374 21.7856 12.5293 21.8643 12.4089 21.9198C12.2886 21.9752 12.1585 22.0062 12.0261 22.0109C11.8937 22.0156 11.7617 21.9939 11.6377 21.9472C11.5138 21.9004 11.4003 21.8295 11.304 21.7386C11.2077 21.6476 11.1304 21.5384 11.0766 21.4173C11.0229 21.2963 10.9937 21.1657 10.9908 21.0333C10.9879 20.9008 11.0114 20.7691 11.0598 20.6458C11.1083 20.5225 11.1807 20.4101 11.273 20.315L19.125 12L11.273 3.687Z" fill="black"/>
              </svg>}
          </ToggleButton>
          </LogoContainer>
          <GroupTitle isOpen={isOpen}>Usuario</GroupTitle>
          { !isAdministrator ? 
              <ItemContainer isOpen={isOpen} onClick={() => goProfile()}>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none">
                  < path d="M8 0.5C9.06087 0.5 10.0783 0.921427 10.8284 1.67157C11.5786 2.42172 12 3.43913 12 4.5C12 5.56087 11.5786 6.57828 10.8284 7.32843C10.0783 8.07857 9.06087 8.5 8 8.5C6.93913 8.5 5.92172 8.07857 5.17157 7.32843C4.42143 6.57828 4 5.56087 4 4.5C4 3.43913 4.42143 2.42172 5.17157 1.67157C5.92172 0.921427 6.93913 0.5 8 0.5ZM8 10.5C12.42 10.5 16 12.29 16 14.5V16.5H0V14.5C0 12.29 3.58 10.5 8 10.5Z" fill="#29166F"/>
                </svg>
                <p>Perfil</p>
              </ItemContainer> :
              <ItemContainer isOpen={isOpen} onClick={() => goToUsers()}> 
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none">
                  < path d="M8 0.5C9.06087 0.5 10.0783 0.921427 10.8284 1.67157C11.5786 2.42172 12 3.43913 12 4.5C12 5.56087 11.5786 6.57828 10.8284 7.32843C10.0783 8.07857 9.06087 8.5 8 8.5C6.93913 8.5 5.92172 8.07857 5.17157 7.32843C4.42143 6.57828 4 5.56087 4 4.5C4 3.43913 4.42143 2.42172 5.17157 1.67157C5.92172 0.921427 6.93913 0.5 8 0.5ZM8 10.5C12.42 10.5 16 12.29 16 14.5V16.5H0V14.5C0 12.29 3.58 10.5 8 10.5Z" fill="#29166F"/>
                </svg>
                <p>Ver Usuarios</p>
              </ItemContainer>
          }
          <GroupTitle isOpen={isOpen}>Analisis</GroupTitle>
          { !isAdministrator ? 
              <ItemContainer isOpen={isOpen} onClick={goHome}>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none">
                  <path d="M0 16.5H16M5.3168 10.6126C5.69657 10.98 6.17162 11.2642 6.70292 11.4418C7.23422 11.6195 7.80661 11.6854 8.37306 11.6342C8.93952 11.5831 9.48387 11.4163 9.9614 11.1476C10.4389 10.8789 10.836 10.5159 11.12 10.0885C11.404 9.6611 11.5669 9.18145 11.5952 8.68896C11.6235 8.19647 11.5164 7.7052 11.2827 7.2555C11.0491 6.80581 10.6955 6.41053 10.2512 6.10213C9.80676 5.79373 9.2842 5.58102 8.7264 5.48147M8.7264 5.48147L6.8 7.14935L4 4.72511L8.376 0.936364C8.51046 0.81992 8.6701 0.727551 8.8458 0.664531C9.0215 0.601511 9.20982 0.569075 9.4 0.569075C9.59018 0.569075 9.7785 0.601511 9.9542 0.664531C10.1299 0.727551 10.2895 0.81992 10.424 0.936364L11.176 1.58745C11.3105 1.70386 11.4172 1.84208 11.49 1.9942C11.5628 2.14632 11.6002 2.30937 11.6002 2.47403C11.6002 2.63868 11.5628 2.80173 11.49 2.95385C11.4172 3.10597 11.3105 3.24419 11.176 3.36061L10.6064 3.85377M8.7264 5.48147L10.6064 3.85377M2.4992 10.6126C2.85532 11.317 3.38721 11.9445 4.05668 12.4499C4.72615 12.9553 5.51658 13.326 6.3712 13.5355M6.3712 13.5355C6.83201 13.2124 7.40701 13.0361 8 13.0361C8.59299 13.0361 9.16799 13.2124 9.6288 13.5355M6.3712 13.5355C6.0839 13.737 5.84984 13.9895 5.6848 14.2759L5.2 15.1147C5.2 15.1147 4.4 16.5 2.8 16.5H13.2C11.6 16.5 10.8 15.1147 10.8 15.1147L10.3152 14.2759C10.1501 13.9895 9.91608 13.737 9.6288 13.5355M3.6 6.80303L4.8 7.84199M0 9.57359H7.2M10.6064 3.85377C11.7035 4.31214 12.6135 5.04867 13.2156 5.96551C13.8177 6.88235 14.0834 7.9361 13.9773 8.98677C13.8712 10.0374 13.3984 11.0353 12.6217 11.8478C11.845 12.6603 10.8011 13.2489 9.6288 13.5355M10.8 1.2619L11.68 0.5" stroke="#A1ACB8"/>
                </svg>
                <p>Ver Analisis</p>
              </ItemContainer> :
              <ItemContainer isOpen={isOpen} onClick={goToAllStudies}>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none">
                  <path d="M0 16.5H16M5.3168 10.6126C5.69657 10.98 6.17162 11.2642 6.70292 11.4418C7.23422 11.6195 7.80661 11.6854 8.37306 11.6342C8.93952 11.5831 9.48387 11.4163 9.9614 11.1476C10.4389 10.8789 10.836 10.5159 11.12 10.0885C11.404 9.6611 11.5669 9.18145 11.5952 8.68896C11.6235 8.19647 11.5164 7.7052 11.2827 7.2555C11.0491 6.80581 10.6955 6.41053 10.2512 6.10213C9.80676 5.79373 9.2842 5.58102 8.7264 5.48147M8.7264 5.48147L6.8 7.14935L4 4.72511L8.376 0.936364C8.51046 0.81992 8.6701 0.727551 8.8458 0.664531C9.0215 0.601511 9.20982 0.569075 9.4 0.569075C9.59018 0.569075 9.7785 0.601511 9.9542 0.664531C10.1299 0.727551 10.2895 0.81992 10.424 0.936364L11.176 1.58745C11.3105 1.70386 11.4172 1.84208 11.49 1.9942C11.5628 2.14632 11.6002 2.30937 11.6002 2.47403C11.6002 2.63868 11.5628 2.80173 11.49 2.95385C11.4172 3.10597 11.3105 3.24419 11.176 3.36061L10.6064 3.85377M8.7264 5.48147L10.6064 3.85377M2.4992 10.6126C2.85532 11.317 3.38721 11.9445 4.05668 12.4499C4.72615 12.9553 5.51658 13.326 6.3712 13.5355M6.3712 13.5355C6.83201 13.2124 7.40701 13.0361 8 13.0361C8.59299 13.0361 9.16799 13.2124 9.6288 13.5355M6.3712 13.5355C6.0839 13.737 5.84984 13.9895 5.6848 14.2759L5.2 15.1147C5.2 15.1147 4.4 16.5 2.8 16.5H13.2C11.6 16.5 10.8 15.1147 10.8 15.1147L10.3152 14.2759C10.1501 13.9895 9.91608 13.737 9.6288 13.5355M3.6 6.80303L4.8 7.84199M0 9.57359H7.2M10.6064 3.85377C11.7035 4.31214 12.6135 5.04867 13.2156 5.96551C13.8177 6.88235 14.0834 7.9361 13.9773 8.98677C13.8712 10.0374 13.3984 11.0353 12.6217 11.8478C11.845 12.6603 10.8011 13.2489 9.6288 13.5355M10.8 1.2619L11.68 0.5" stroke="#A1ACB8"/>
                </svg>
                <p>Ver Analisis</p>
              </ItemContainer>
          }
          { isAdministrator ? <>
            <GroupTitle isOpen={isOpen}>Pre Cargados</GroupTitle>
            <ItemContainer isOpen={isOpen} onClick={goToPreLoads}>
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path d="M14 13C13.1667 13 12.4583 12.7083 11.875 12.125C11.2917 11.5417 11 10.8333 11 10C11 9.16667 11.2917 8.45833 11.875 7.875C12.4583 7.29167 13.1667 7 14 7C14.8333 7 15.5417 7.29167 16.125 7.875C16.7083 8.45833 17 9.16667 17 10C17 10.8333 16.7083 11.5417 16.125 12.125C15.5417 12.7083 14.8333 13 14 13ZM7 16C6.45 16 5.979 15.804 5.587 15.412C5.195 15.02 4.99934 14.5493 5 14V6C5 5.45 5.196 4.979 5.588 4.587C5.98 4.195 6.45067 3.99934 7 4H21C21.55 4 22.021 4.196 22.413 4.588C22.805 4.98 23.0007 5.45067 23 6V14C23 14.55 22.804 15.021 22.412 15.413C22.02 15.805 21.5493 16.0007 21 16H7ZM9 14H19C19 13.45 19.196 12.979 19.588 12.587C19.98 12.195 20.4507 11.9993 21 12V8C20.45 8 19.979 7.804 19.587 7.412C19.195 7.02 18.9993 6.54934 19 6H9C9 6.55 8.804 7.021 8.412 7.413C8.02 7.805 7.54934 8.00067 7 8V12C7.55 12 8.021 12.196 8.413 12.588C8.805 12.98 9.00067 13.4507 9 14ZM20 20H3C2.45 20 1.979 19.804 1.587 19.412C1.195 19.02 0.999335 18.5493 1 18V7H3V18H20V20Z" fill="#A1ACB8"/>
              </svg>
              <p>Pre Cargados</p>
            </ItemContainer> </> :
          null }
          <LogoutContainer isOpen={isOpen} onClick={goToLogout}>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
              <path d="M4.77778 19C4.28889 19 3.87022 18.8258 3.52178 18.4773C3.17333 18.1289 2.99941 17.7105 3 17.2222V4.77778C3 4.28889 3.17422 3.87022 3.52267 3.52178C3.87111 3.17333 4.28948 2.99941 4.77778 3H11V4.77778H4.77778V17.2222H11V19H4.77778ZM14.5556 15.4444L13.3333 14.1556L15.6 11.8889H8.33333V10.1111H15.6L13.3333 7.84445L14.5556 6.55556L19 11L14.5556 15.4444Z" fill="#A1ACB8"/>
            </svg>
            <p>Cerrar Sesion</p>
          </LogoutContainer>
        </Container>
      </SidebarWrapper>
    </div>
  );
}

export default Header;
