import { useDispatch, useSelector } from "react-redux";
import DataTable from "../../commons/DataTable";
import { columns } from "./columns";
import { selectAllPreloadStudies } from "../../../store/Studies/selectors";
import { isAdmin } from "../../../store/Login/selectors";
import { useEffect, useState } from "react";
import { getAllPreloadStudies } from "../../../store/Studies/actions";
import { fetchPDF, setPayment, setNewOrders, setCost, setFindPdf, setOrder } from "../../../store/Users/actions";
import PaymentModal from "../../commons/Users/UserStudiesTable/Components/PaymentModal";
import { getUser } from '../../../store/Login/selectors';
import { BasicButton } from "../../commons/Button";

const PreLoads = () => {

    const [showPaymentModal, setShowPaymentModal] = useState(false);
    const [showCostModal, setShowCostModal] = useState(false);
    const [selectedStudy, setSelectedStudy] = useState(null);
    const [file, setFile] = useState(null);
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const user = useSelector(getUser);

    const data = useSelector(selectAllPreloadStudies)

    const dispatch = useDispatch();

    useEffect(() => {
        // Set default interval to last month
        const currentDate = new Date();
        const previousDate = new Date();
        previousDate.setDate(currentDate.getDate() - 30);
        setStartDate(previousDate.toISOString().slice(0, 10));
        setEndDate(currentDate.toISOString().slice(0, 10));
        dispatch(getAllPreloadStudies(previousDate.toISOString().slice(0, 10),currentDate.toISOString().slice(0, 10)))
    }, [dispatch])

    const getPDF = (id) => {
        dispatch(fetchPDF(id))
    }

    const payButtonAction = (id) => {
        setShowPaymentModal(true);
        setSelectedStudy(id);
    }

    const costButtonAction = (id) => {
        setShowCostModal(true);
        setSelectedStudy(id);
    }

    const onSavePayment = (amount) => {
        dispatch(setPayment(selectedStudy, amount, 1, startDate, endDate))
        setShowPaymentModal(false);
    }

    const onSaveCost = (amount) => {
        dispatch(setCost(selectedStudy, amount, 1, startDate, endDate))
        setShowCostModal(false);
    }

    const onCancelPayment = () => {
        setShowPaymentModal(false);
        setShowCostModal(false);
    }

    const reloadButtonAction = (id) => {
        dispatch(setFindPdf(id))
    }

    const orderButtonAction = (id, val) => {
        dispatch(setOrder(id, val))
    }

    const handleFileUpload = (event) => {
        setFile(event.target.files[0]);
    };

    const publishNewOrder = () => {
        dispatch(setNewOrders(file, startDate, endDate))
    }

    const handleDateFilter = () => {
        dispatch(getAllPreloadStudies(startDate, endDate))
    }

    return (
        <div>
            <PaymentModal isOpen={showPaymentModal} onAccept={onSavePayment} onClose={onCancelPayment} text={'Ingrese un valor:'} />
            <PaymentModal isOpen={showCostModal} onAccept={onSaveCost} onClose={onCancelPayment} text={'Ingrese El costo de la orden:'} />
            {
              (isAdmin) ? (
                <div>
                  <input type="file" onChange={handleFileUpload} />
                  <BasicButton onClick={() => publishNewOrder()}>Publicar Archivo</BasicButton>
                  <br></br>
                  <input type="date" value={startDate} onChange={(e) => setStartDate(e.target.value)} />
                  <input type="date" value={endDate} onChange={(e) => setEndDate(e.target.value)} />
                  <BasicButton onClick={() => handleDateFilter()}>Filtrar</BasicButton>
                </div>
              ) : null
            }
            <DataTable columns={columns(payButtonAction, costButtonAction, reloadButtonAction, orderButtonAction, true)} data={data} />
        </div>
    );
}

export default PreLoads