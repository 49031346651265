import { useDispatch, useSelector } from "react-redux";
import DataTable from "../../commons/DataTable";
import { columns } from "./columns";
import { selectAllStudies } from "../../../store/Studies/selectors";
import { isAdmin } from "../../../store/Login/selectors";
import { useEffect, useState } from "react";
import { getAllStudies } from "../../../store/Studies/actions";
import { fetchPDF, setPayment, setSignature, setReloadPdf, setPublishUrgency } from "../../../store/Users/actions";
import PaymentModal from "../../commons/Users/UserStudiesTable/Components/PaymentModal";
import SignatureModal from '../../commons/Users/UserStudiesTable/Components/SignatureModal';
import { setPagesToSing } from '../../../store/General/actions';
import { getUser } from '../../../store/Login/selectors';
import { BasicButton } from "../../commons/Button";

const Studies = () => {

    const [showPaymentModal, setShowPaymentModal] = useState(false);
    const [selectedStudy, setSelectedStudy] = useState(null);
    const [showSignatureModal, setShowSignatureModal] = useState(false);
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const user = useSelector(getUser)

    const data = useSelector(selectAllStudies)

    const dispatch = useDispatch();

    useEffect(() => {
        // Set default interval to last month
        const currentDate = new Date();
        const previousDate = new Date();
        previousDate.setDate(currentDate.getDate() - 30);
        setStartDate(previousDate.toISOString().slice(0, 10));
        setEndDate(currentDate.toISOString().slice(0, 10));
        dispatch(getAllStudies(previousDate.toISOString().slice(0, 10),currentDate.toISOString().slice(0, 10)))
    }, [dispatch])

    const getPDF = (id) => {
        dispatch(fetchPDF(id))
    }

    const payButtonAction = (id) => {
        setShowPaymentModal(true);
        setSelectedStudy(id);
      }

    const onSavePayment = (amount) => {
        dispatch(setPayment(selectedStudy, amount, null, startDate, endDate))
        setShowPaymentModal(false);
    }

    const onCancelPayment = () => {
        setShowPaymentModal(false);
    }

    const singButtonAction = (id) => {
      setShowSignatureModal(true);
      setSelectedStudy(id);
      const selectedElement = data.find((element) => element.id === id);
      const pagesToSing = selectedElement ? selectedElement.pages : [];
      dispatch(setPagesToSing(pagesToSing));
    }

    const reloadButtonAction = (id, sing) => {
      dispatch(setReloadPdf(id, sing))
    }
    
    const onSaveSignature = (size) => {
      dispatch(setSignature(selectedStudy, size))
      setShowSignatureModal(false);
      setPagesToSing([]);
    }
  
    const onCancelSignature = () => {
      setShowSignatureModal(false);
      setPagesToSing([]);
    }

    const publishUrgency = () => {
      dispatch(setPublishUrgency(startDate,endDate))
    }

    const handleDateFilter = () => {
      dispatch(getAllStudies(startDate,endDate))
    }

    return (
        <div>
            <PaymentModal isOpen={showPaymentModal} onAccept={onSavePayment} onClose={onCancelPayment} text={'Ingrese un valor:'} />
            <SignatureModal isOpen={showSignatureModal} onAccept={onSaveSignature} onClose={onCancelSignature}/>
            {
              (isAdmin) ? (
                <div>
                  <BasicButton onClick={() => publishUrgency()}>Publicar Nuevos</BasicButton>
                  <br></br>
                  <input type="date" value={startDate} onChange={(e) => setStartDate(e.target.value)} />
                  <input type="date" value={endDate} onChange={(e) => setEndDate(e.target.value)} />
                  <BasicButton onClick={() => handleDateFilter()}>Filtrar</BasicButton>
                </div>
              ) : null
            }
            <DataTable columns={columns(payButtonAction, getPDF, singButtonAction, reloadButtonAction, true, user.id)} data={data} />
        </div>
    );
}

export default Studies