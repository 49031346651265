import React from "react";
import { AdditionalText, CenteredText, CenteredTextContainer, Container, FooterBlock, FooterContainer, FooterContent, FooterText, FooterTitle, IconTextContainer, Link, LinkBeforeFooter } from "./styled";
import EmailIcon from "./Components/images/EmailIcon";
import WhatsAppIcon from "./Components/images/WhatsappIcon";
import PhoneIcon from "./Components/images/PhoneIcon";
import FacebookIcon from "./Components/images/FacebookIcon";
import InstagramIcon from "./Components/images/InstagramIcon";

const Footer = () => {
    return (
        <Container id="footer">
            <FooterContainer>
                <FooterContent>
                    <FooterBlock>
                        <FooterTitle>Dirección</FooterTitle>
                        <FooterText>Av. Rivadavia 3355</FooterText>
                        <FooterText>(Saladillo, Buenos Aires)</FooterText>
                        <Link href="https://goo.gl/maps/QVjFuCJKEysVhiBb9">Ver ubicación</Link>
                    </FooterBlock>
                    <FooterBlock>
                        <FooterTitle>Horarios de atención</FooterTitle>
                        <FooterText>Lunes a viernes: 7:30 a 13:00 | 16:00 a 20:00</FooterText>
                        <FooterText>Sábados: 8:00 a 12:00</FooterText>
                        <AdditionalText>Realizamos extracciones a domicilio</AdditionalText>
                    </FooterBlock>
                    <FooterBlock>
                        <FooterTitle>Contacto</FooterTitle>
                        <IconTextContainer>
                            <EmailIcon />
                            <Link href="mailto:laboratoriogincast@gmail.com">laboratoriogincast@gmail.com</Link>
                        </IconTextContainer>
                        <IconTextContainer>
                            <PhoneIcon  />
                            <FooterText>2344-433843</FooterText>
                        </IconTextContainer>
                        <IconTextContainer>
                            <WhatsAppIcon />
                            <Link href="https://api.whatsapp.com/send?phone=5492345526042">2345-526042</Link>
                        </IconTextContainer>
                    </FooterBlock>
                </FooterContent>
            </FooterContainer>
            <CenteredTextContainer>
                <CenteredText>
                    Te invitamos a seguirnos en nuestras redes
                </CenteredText>
                <IconTextContainer  marginTop={"18px"}>
                    <FacebookIcon />
                <LinkBeforeFooter href="https://www.facebook.com/gin.cast.944">Gin Cast</LinkBeforeFooter>
                </IconTextContainer>
                <IconTextContainer  marginTop={"18px"}>
                    <InstagramIcon />
                    <LinkBeforeFooter href="https://www.instagram.com/laboratorio.gincast/">laboratorio.gincast</LinkBeforeFooter>
                </IconTextContainer>
            </CenteredTextContainer>
        </Container>

    )
}

export default Footer;