import { BasicButton } from "../../commons/Button";
import ColumnFilter from "./columnFilter";


export const columns = (infoAction, showStudiesAction) => {
  return [
    { Header: 'Nombre', accessor: 'name', Filter: ColumnFilter },
    { Header: 'DNI', accessor: 'dni', Filter: ColumnFilter },
    { Header: 'Email', accessor: 'email', Filter: ColumnFilter },
    { Header: 'Teléfono', accessor: 'phone', Filter: ColumnFilter },
    { Header: 'Obra social', accessor: 'medicare', Filter: ColumnFilter },
    {
      Header: '',
      hideHeader: true,
      accessor: 'info',
      Cell: ({ row }) => (
        <BasicButton onClick={() => infoAction(row.original)}> Informacion </BasicButton>
      ),
    },
    {
      Header: '',
      hideHeader: true,
      accessor: 'link',
      Cell: ({ row }) => (
        <BasicButton onClick={() => showStudiesAction(true, row.original)}> Ver estudios </BasicButton>
      ),
    },
  ]
};
