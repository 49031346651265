import styled from 'styled-components';

export const Table = styled.table`
  border-collapse: collapse;
  width: 100%;
  border: 1px solid #ddd;
`;

export const Th = styled.th`
  padding: 8px;
  background: #453C3C;
  color: #fff;
`;

export const Td = styled.td`
  padding: 8px;
`;

export const Tr = styled.tr`
  &:nth-child(even) {
    background-color: #f2f2f2;
  }
`;
