import React, { useEffect, useState } from 'react';
import DataTable from '../../DataTable';
import { columns } from './columns';
import { BasicButton } from '../../Button';
import { useDispatch, useSelector } from 'react-redux';
import { userStudies } from '../../../../store/Users/selectors';
import { fetchPDF, getUserStudies, setPayment, setSignature } from '../../../../store/Users/actions';
import PaymentModal from './Components/PaymentModal';
import SignatureModal from './Components/SignatureModal';
import { isAdmin, getUser } from '../../../../store/Login/selectors';
import { setPagesToSing } from '../../../../store/General/actions';

const UserStudiesTable = ({goBackAction, user}) => {

  const [showPaymentModal, setShowPaymentModal] = useState(false);
  const [showSignatureModal, setShowSignatureModal] = useState(false);
  const [selectedStudy, setSelectedStudy] = useState(null);

  const data = useSelector(userStudies);
  const isAdministrator = useSelector(isAdmin)
  const userA = useSelector(getUser)
  const dispatch = useDispatch();


  useEffect(() => {
    dispatch(getUserStudies(user))
  }, [dispatch])

  const payButtonAction = (id) => {
    setShowPaymentModal(true);
    setSelectedStudy(id);
  }

  const onSavePayment = (amount) => {
    const currentDate = new Date();
    const previousDate = new Date();
    previousDate.setDate(currentDate.getDate() - 30);
    dispatch(setPayment(selectedStudy, amount, user, previousDate.toISOString().slice(0, 10), currentDate.toISOString().slice(0, 10)))
    setShowPaymentModal(false);
  }

  const onCancelPayment = () => {
    setShowPaymentModal(false);
  }

  const getPDF = (id) => {
    dispatch(fetchPDF(id))
  }

  const singButtonAction = (id) => {
    setShowSignatureModal(true);
    setSelectedStudy(id);
    const selectedElement = data.find((element) => element.id === id);
    const pagesToSing = selectedElement ? selectedElement.pages : [];
    dispatch(setPagesToSing(pagesToSing));
  }

  const onSaveSignature = (pageId, pageNum, size) => {
    dispatch(setSignature(selectedStudy, pageNum, user, pageId, size))
    setShowSignatureModal(false);
    setPagesToSing([]);
  }

  const onCancelSignature = () => {
    setShowSignatureModal(false);
    setPagesToSing([]);
  }

  return (
    <div>
      <PaymentModal isOpen={showPaymentModal} onAccept={onSavePayment} onClose={onCancelPayment} text={'Ingrese un valor:'} />
      <SignatureModal isOpen={showSignatureModal} onAccept={onSaveSignature} onClose={onCancelSignature}/>
      {goBackAction && <BasicButton onClick={() => goBackAction(false)}>Volver</BasicButton>}
      <DataTable columns={columns(payButtonAction, getPDF, singButtonAction, isAdministrator, userA.id)} data={data} />
    </div>
  );
}

export default UserStudiesTable;
