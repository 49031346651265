import styled from "styled-components";
import { colors } from "../../../utils/colors";

export const Container = styled.header`
  display: flex;
  flex-direction: column;
  align-items: ${props => (props.isOpen ? 'start' : 'center')};
  padding: ${props => (props.isOpen ? '0 32px' : '0 15px')};
  background-color: ${colors["header-background"]};
  color: ${colors.logo};
`;

export const SidebarWrapper = styled.div`
  width: ${props => (props.isOpen ? '19vw' : '8vw')}; /* Adjust the widths as needed */
  height: 100vh;
  background-color: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  transition: width 0.3s ease-in-out;
  padding: ${props => (props.isOpen ? '0 20px' : '0')};
`;

export const ToggleButton = styled.button`
  background-color: transparent;
  color: #000;
  border: none;
  padding: 5px 10px;
  border-radius: 4px;
  cursor: pointer;
  transition: left 0.3s ease-in-out;
`;

export const Title = styled.h1`
  display: ${props => (props.isOpen ? 'block' : 'none')};
  font-size: 28px;
  font-weight: 700;
  margin: 0;
  letter-spacing: 1px;
`;

export const GroupTitle = styled.p`
  display: ${props => (props.isOpen ? 'block' : 'none')};
  color: #A1ACB8;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
`;

export const ItemContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: ${props => (props.isOpen ? '20px' : '0')};
  margin-bottom: ${props => (props.isOpen ? '0' : '30px')};
  cursor: pointer;

  p{
    display: ${props => (props.isOpen ? 'block' : 'none')};
    margin-left: 20px;
    color: #29166F;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 23px;
  }
`;

export const LogoutContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top:50px;
  margin-left: 0px;
  cursor:pointer;

  p{
    display: ${props => (props.isOpen ? 'block' : 'none')};
    margin-left: 20px;
    color: #29166F;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 23px;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  margin-left: auto;
  margin-right: 32px;
`;

export const Button = styled.button`
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 40px;
  padding: 0 20px;
  border-radius: 20px;
  font-size: 16px;
  font-weight: 500;
  text-transform: uppercase;
  background-color: #fff;
  color: ${colors.blue};
  margin-left: 16px;
  cursor: pointer;
  border: none;

  &:hover {
    background-color: #f5f5f5;
  }
`;

export const ProfileButtonContainer = styled.div``

export const ProfileButton = styled.div`
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 40px;
  padding: 0 20px;
  border-radius: 20px;
  font-size: 16px;
  font-weight: 500;
  text-transform: uppercase;
  background-color: #fff;
  color: ${colors.blue};
  margin-left: 32px;
  position: relative;
  cursor: pointer;

  &:hover > div {
    display: block;
  }
`;

export const DropdownContainer = styled.div`
  display: none;
  position: absolute;
  background-color: #fff;
  z-index: 1;
  top: 40px;
  box-shadow: 10px 10px 15px 10px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
`;

export const DropdownList = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0;
`;

export const DropdownItem = styled.button`
  display: block;
  width: 100%;
  height: 50px;
  padding: 0 10px;
  border: none;
  background-color: transparent;
  color: #333;
  text-align: left;
  font-size: 14px;
  cursor: pointer;
  border-radius: 10px;

  &:hover {
    background-color: #f5f5f5;
  }
`;

export const DropdownLink = styled.a`
  color: ${colors.blue};
  font-size: 16px;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;

export const Logo = styled.img`
  width: 50px;
  padding: ${props => (props.isOpen ? '0px 10px' : '0')};
`;

export const LogoContainer = styled.div`
  display:flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  padding: 32px 0;
`;
