import React from "react";
import { Container, ButtonContainer, Button } from "./styled";


const Header = () => {

  const scrollToSection = (sectionId) => {
    const targetSection = document.getElementById(sectionId || 'home');
    targetSection.scrollIntoView({ behavior: "smooth" });
  };

  const goToLogin = () => {
    window.location.assign("/login"); // Reemplaza "/nueva-ruta" con la URL de destino
  }

  return (
    <Container>
      <ButtonContainer>
        <Button onClick={() => scrollToSection('home')}>Inicio</Button>
        <Button onClick={() => scrollToSection('services')}>Servicios</Button>
        <Button onClick={goToLogin}>Acceso</Button>
        <Button onClick={() => scrollToSection('about')}>Nosotros</Button>
        <Button onClick={() => scrollToSection('footer')}>Contacto</Button>
      </ButtonContainer>
    </Container>
  );
}

export default Header;
