import React, { useEffect } from 'react';
import DataTable from '../../commons/DataTable';
import { columns } from './columns';
import { useState } from 'react';
import UserDetail from '../../commons/Users/UserDetail';
import UserStudiesTable from '../../commons/Users/UserStudiesTable';
import { useDispatch, useSelector } from 'react-redux';
import { usersSelector } from '../../../store/Users/selectors';
import { getUsers, updateUser } from '../../../store/Users/actions';
import { setPageTitle } from '../../../store/General/actions';
//import { SectionButtonWrapper, SectionTitle } from './styled';
//import { BasicButton } from '../../commons/Button';

const UsersTable = () => {
  const [selectedUser, setSelectedUser] = useState(null);
  const [showStudies, setShowStudies] = useState(false);
  const [showUserDetail, setShowUserDetail] = useState(false);

  const data = useSelector(usersSelector);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getUsers())
  }, [dispatch])

  const infoAction = (user) => {
    setSelectedUser(user)
    setShowUserDetail(true);
    dispatch(setPageTitle('Datos del Usuario'));
  }

  const goBackDetailAction = () => {
    setSelectedUser(null);
    setShowUserDetail(false);
    setShowStudies(false);
    dispatch(setPageTitle('Listado de Usuarios'));
  }

  const showStudiesAction = (value, user) => {
    setShowStudies(value);
    setSelectedUser(user);
    setShowUserDetail(false);
    value ? dispatch(setPageTitle('Análisis del Usuario: ' + user.name)) : dispatch(setPageTitle('Listado de Usuarios'));
  }

  const validateUser = (user) => {
    if (!user.username || !user.name || !user.dni) {
      return false;
    }
    return true;
  }

  const saveAction = (user) => {
    if (validateUser(user)) {
      dispatch(updateUser(user, true));
      setSelectedUser(null);
      dispatch(setPageTitle('Listado de Usuarios'));
    } 
  }

  return (
    <div>
      {!selectedUser && !showStudies && <DataTable columns={columns(infoAction, showStudiesAction)} data={data} />}
      {showUserDetail && <UserDetail userData={selectedUser} goBackAction={goBackDetailAction} saveAction={saveAction} />}
      {showStudies && <UserStudiesTable goBackAction={showStudiesAction} user={selectedUser}/>}
    </div>
  );
}

export default UsersTable;
