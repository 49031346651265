import styled from 'styled-components';
import { colors } from '../../../utils/colors';
import "@fontsource/montserrat"; // Defaults to weight 400
import "@fontsource/montserrat/400.css"; // Specify weight
import "@fontsource/montserrat/400-italic.css";

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  background-color: ${colors['login-background']};
`;

export const LoginContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2rem;
  border-radius: 10px;
  background-color: transparent;
  width: 400px;
  height: auto;
`;

export const Title = styled.h1`
  color: ${colors.colo1};
  margin-bottom: 1.5rem;
`;

export const Logo = styled.img`
  width: 100px;
  margin-bottom: 2rem;
`;

export const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
  z-index: 2;
  input {
    margin-top: 15px;
  }
`;

export const CenteredText = styled.p`
  text-align: center;
  font-size: 20px; /* Ajusta el tamaño de fuente según tus necesidades */
  color: #000;
  text-align: center;
  font-family: Montserrat;
  font-style: italic;
  font-weight: 300;
  line-height: 26px; /* 130% */
`;

export const DialogWrapper = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  padding: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  z-index: 9999;
  text-align: center;
  border: 1px solid rgba(41, 22, 111, 0.70);

  h2 {
    margin-bottom: 30px;
    text-align: center;
    font-size: 30px; /* Ajusta el tamaño de fuente según tus necesidades */
    color: #000;
    text-align: center;
    font-family: Montserrat;
    font-style: italic;
    font-weight: 300;
    line-height: 26px; /* 130% */
  }

  p {
    text-align: center;
    font-size: 20px; /* Ajusta el tamaño de fuente según tus necesidades */
    color: #000;
    text-align: center;
    font-family: Montserrat;
    font-style: italic;
    font-weight: 300;
    line-height: 26px; /* 130% */
  }

  button {
    padding: 8px 16px;
    background-color: rgba(41, 22, 111, 0.70);
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;

  }
`;