import styled from 'styled-components';

export const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
`;

export const ModalContainer = styled.div`
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
`;

export const ModalText = styled.p`
  margin-bottom: 10px;
`;

export const ModalButtons = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const ModalButton = styled.button`
  padding: 5px 10px;
  margin-left: 10px;
  cursor: pointer;
`;

export const ModalSelect = styled.select`
  margin-bottom: 10px;
`;