import { BasicButton } from "../../commons/Button";
import ColumnFilter from "./columnFilter";
//import { Dropdown, DropdownMenu, DropdownItem } from "./styled";

export const columns = (payButtonAction, getPDFAction, signatureAction, reloadAction, isAdmin, userId) => {
  return [
    { Header: 'Paciente', accessor: 'pacient', Filter: ColumnFilter },
    { Header: 'Fecha', accessor: 'file_date', Filter: ColumnFilter },
    { Header: 'Orden', accessor: 'order_number', Filter: ColumnFilter },
    { Header: 'Costo', accessor: 'cost', Filter: ColumnFilter },
    { Header: 'Pago', accessor: 'amount', Filter: ColumnFilter },
    {
      Header: 'Info',
      id: 'info',
      Cell: ({ row }) => (
        (row.original.orden == 1 ? 'Tiene orden' : 'No tiene orden') +
        (row.original.derivaciones.length > 0 ? ', Derivacion cargada' : '')
      ),
    },
    {
      Header: '',
      hideHeader: true,
      id: 'payment',
      Cell: ({ row }) => (
        row.original.payment === 'No' ? (isAdmin ? (
          <BasicButton onClick={() => payButtonAction(row.original.order_number)}>Pagar</BasicButton>
        ) : 'No') : (
          'Pago'
        )
      ),
    },
    {
      Header: '',
      hideHeader: true,
      id: 'seePDF',
      Cell: ({ row }) => (
        isAdmin || row.original.payment === 'Si' ? (
          <BasicButton onClick={() => getPDFAction(row.original.id)}>Ver Analisis</BasicButton>
        ) : null
      ),
    },
    {
      Header: '',
      hideHeader: true,
      id: 'singPDF',
      Cell: ({ row }) => (
        (isAdmin && (userId === 199 || userId === 201 || userId === 202 || userId === 203 || userId === 18)) ? (
          <BasicButton onClick={() => signatureAction(row.original.id)}>Firmar Analisis</BasicButton>
        ) : null
      ),
    },
    {
      Header: '',
      hideHeader: true,
      id: 'reloadPdfWithSing',
      Cell: ({ row }) => (
        (isAdmin) ? (
          <BasicButton onClick={() => reloadAction(row.original.name, true)}>Recargar</BasicButton>
        ) : null
      ),
    },
    {
      Header: '',
      hideHeader: true,
      id: 'reloadPdf',
      Cell: ({ row }) => (
        (isAdmin) ? (
          <BasicButton onClick={() => reloadAction(row.original.name, false)}>Quitar Firma</BasicButton>
        ) : null
      ),
    },
    /* {
      Header: '',
      hideHeader: true,
      id: 'buttons',
      Cell: ({ row }) => (
        (isAdmin) ? (
          <Dropdown>
            <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              Dropdown button
            </button>
            <DropdownMenu aria-labelledby="dropdownMenuButton">
              <DropdownItem>Action</DropdownItem>
              <DropdownItem>Another action</DropdownItem>
              <DropdownItem>Something else here</DropdownItem>
            </DropdownMenu>
          </Dropdown>
        ) : null
      ),
    }, */
  ]
};


