import styled from "styled-components";

export const Container = styled.div`
  display: flex;
`;

export const SquareContainer = styled.div`
  width: 150px;
  height: 150px;
  background-color: #2C2A51;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const TextContainer = styled.div`
  margin-left: 10px;
`;

export const Title = styled.h2`
  max-width: 200px;
  margin-bottom: 5px;
  overflow-wrap: break-word;
  color: #000;
  font-family: Montserrat;
  font-size: 14px;
  font-style: bold;
  line-height: normal;
  letter-spacing: 0.7px;
`;

export const Description = styled.p`
  font-size: 14px;
  word-wrap: break-word;
  color: #000;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.7px;
`;

export const ImgIcon = styled.img`
  width: 100%;
  height: 100px;
  object-fit: cover;
`;