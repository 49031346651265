import styled from "styled-components";
import { colors } from "../../../../../../../utils/colors";

export const BoxContainer = styled.div`
  margin: 40px 100px;
  display: flex;
  border-radius: 50px;
  padding: 10px;
  background-color: #5D5092;

  @media (max-width: 768px) {
    flex-direction: column;
    margin: 4px 10px;
  }
`;

export const Title = styled.h1`
  margin-left: 30px;
  color: ${colors["text-white"]};
  margin-bottom: 30px;
  font-family: Montserrat;
  font-size: 29.13px;
  font-style: italic;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 1.456px;
`;

export const ContentContainer = styled.div`
  display: flex;
  flex: 1.5;
  flex-direction: column;
`;

export const TextContainer = styled.div`
  color: ${colors["text-white"]};
  margin-left: 50px;
  margin-right: 100px;
  max-width: 700px;
  text-align: center;
  margin-bottom: 20px; /* Ajusta el margen inferior para que el texto arranque más arriba */
  font-family: Montserrat;
  font-size: 18.206px;
  font-style: italic;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 1.456px;
  @media (max-width: 768px) {
    margin: 0;
  }
`;
