import React from "react";

const CrossIcon = ({ size = 24, color = "currentColor", ...props }) => {
    return (
        <svg
            viewBox="0 0 24 24"
            width={size}
            height={size}
            fill={color}
            {...props}
        >
            <path
                fillRule="evenodd"
                d="M12.707 12l8.182 8.182a1 1 0 0 1-1.414 1.414L11.293 13.414l-8.182 8.182a1 1 0 0 1-1.414-1.414L9.879 12 1.697 3.818a1 1 0 0 1 1.414-1.414L11.293 10.586l8.182-8.182a1 1 0 0 1 1.414 1.414L12.707 12z"
            />
        </svg>
    );
}

export default CrossIcon;
