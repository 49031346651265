import React from "react";
import Header from "./Components/Header";
import { Container } from "./styled";
import FirstBlock from "./Components/FirstBlock";
import SecondBlock from "./Components/SecondBlock";
import Footer from "./Components/Footer";
import ServicesBlock from "./Components/ServicesBlock";
import AboutBlock from "./Components/AboutBlock";
import MissionBlock from "./Components/MissionBlock";
import TeamBlock from "./Components/TeamBlock";

const Landing = () => {
    return (
        <Container>
            <Header />
            <FirstBlock />
            <SecondBlock />
            <TeamBlock />
            <ServicesBlock />
            <AboutBlock />
            <MissionBlock />
            <Footer />
        </Container>
    )
}

export default Landing;