export const colors = {
    "login-background": "#C4C4C4",
    "footer": "#9893BA",
    "purple": "#8644ff",
    "light-purple": "#56347c8c",
    "logo": "#322D75",
    "dark-gray": "#424242",
    "light-gray": "#E5E5E5",
    "primary": "#007bff",
    "success": "#28a745",
    "warning": "#ffc107",
    "danger": "#dc3545",
    "info": "#17a2b8",
    "text-primary": "#212529",
    "text-secondary": "#6c757d",
    "text-muted": "#adb5bd",
    "text-white": "#fff",
    "text-dark": "#343a40",
    "text-light": "#f8f9fa",
    "blue": "#0c2d6b",
    "link": "#007bff",
    "link-hover": "#0056b3",
    "border": "#dee2e6",
    "border-dark": "#343a40",
    "black": "#000000",
    "header-text": "#4e4d4d",
    "header-backgroud": "#dcdfe7",
}
