import styled, { keyframes } from 'styled-components';
import { colors } from '../../../utils/colors';

export const LoadingContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 9999; /* asegurarse de que el spinner esté en la capa superior */
`;

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

export const Spinner = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 3px solid ${colors.colo1};
  border-top-color: ${colors['login-background']};
  animation: ${rotate} 1s ease-in-out infinite;
`;

