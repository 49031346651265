import React from "react";

const BackgroundSVGLeft = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="790" height="668" viewBox="0 0 790 668" fill="none">
        <path d="M552.643 572.807C677.663 560.199 762.701 659.492 789.592 710.714L-6.99959 707.267L-6.99959 -1.86733e-05H81.9716C357.459 37.4319 311.084 186.667 253.46 256.606C219.808 305.694 158.957 423.966 184.772 504.346C217.042 604.821 396.367 588.568 552.643 572.807Z" fill="#29166F" fillOpacity="0.7"/>
    </svg>

  );
};

export default BackgroundSVGLeft;