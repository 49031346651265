import styled from "styled-components";
import "@fontsource/montserrat"; // Defaults to weight 400
import "@fontsource/montserrat/400.css"; // Specify weight
import "@fontsource/montserrat/400-italic.css";

export const Container = styled.div`
  width: 100%;
  margin: 0 auto;
  box-sizing: border-box;
  font-family: Montserrat!important;
`;

export const SidebarAndContentContainer = styled.div`
  display: flex;
  width: 100vw;
`;

export const SectionButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  background: #29166F;
  width: fit-content;
  margin: 40px 0;
`;

export const SectionTitle = styled.h1`
  color: #FFF;
  text-align: center;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.40);
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

