import React, { useEffect, useState } from 'react';
import { Container, LoginContainer, StyledForm, Logo, CenteredText, /* DialogWrapper */ } from './styled';
import { Input } from '../../commons/Input';
import { BasicButton } from '../../commons/Button';
import logo from '../../../images/gin-cast-logo.png'
import { useDispatch, useSelector } from 'react-redux';
import { login } from '../../../store/Login/actions';
import { getUser, selectToken } from '../../../store/Login/selectors';
import { useNavigate } from 'react-router-dom';
import Error from '../../commons/ErrorText';
import BackgroundWrapper from "./Components/BackgroundWrapper";
import { setPageTitle } from '../../../store/General/actions';

const Login = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');

  const dispatch = useDispatch()
  const navigate = useNavigate();
  const isAuthenticated = useSelector(selectToken) || localStorage.getItem('user');
  const user = useSelector(getUser)

  const validateForm = () => {
    if (username.length === 0 || password.length === 0) {
      setError("Debe completar ambos campos.")
      return false
    }
    setError("")
    return true
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    if (validateForm()) {
      dispatch(login(username, password));
    }
    //handleShowDialog();
  };

  useEffect(() => {
    if (isAuthenticated) {
      if (user.role === 'admin') {
        navigate('/users', { replace: true });
        dispatch(setPageTitle('Listado de Usuarios'));
      } else {
        dispatch(setPageTitle('Mis Analisis'));
        navigate('/home', { replace: true });
      }
    }
  }, [isAuthenticated, navigate]);

  /* // State to manage the dialog visibility
  const [showDialog, setShowDialog] = useState(true);

  // Event handler for showing the dialog
  const handleShowDialog = () => {
    setShowDialog(true);
  }; */

  return (
    <BackgroundWrapper>
      <Container>
        <LoginContainer>
          <Logo src={logo} alt="Logo" />
          <CenteredText>Laboratorio GinCast</CenteredText>
          <CenteredText>Bienvenido</CenteredText>
          <StyledForm onSubmit={handleSubmit}>
            <Input type="text" placeholder="Usuario" value={username} onChange={(event) => setUsername(event.target.value)} />
            <Input type="password" placeholder="Contraseña" value={password} onChange={(event) => setPassword(event.target.value)} />
            <Error text={error} />
            <BasicButton type="submit">Ingresar</BasicButton>
            {/* <Link to={"/signin"} children={"¿Aún no tenés cuenta? Registrarse"} /> */}
          </StyledForm>
        </LoginContainer>
      </Container>
      {/* {showDialog && (
        <DialogWrapper>
          <h2>En Desarrollo</h2>
          <p>Pronto podras acceder a tus analisis desde este portal</p>
          <button onClick={() => setShowDialog(false)}>OK</button>
        </DialogWrapper>
      )} */}
    </BackgroundWrapper>
  );
};

export default Login;
