import styled from "styled-components";

export const Wrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const BackgroundStack = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  right: 0px;
  top: 0px;
  @media (max-width: 768px) {
    display:none;
  }
`;

export const BackgroundLeft = styled.div`
  /* You can add any specific styling for each BackgroundLeft component here */
  @media (max-width: 768px) {
    display:none;
  }
`;

export const BackgroundRight = styled.div`
  position: absolute;
  left: 0px;
  bottom: 0px;
  @media (max-width: 768px) {
    display:none;
  }
`;