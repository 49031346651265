import * as types from './types';


export const setLoading = (loading) => {
    return {
        type: types.SET_LOADING,
        loading
    }
}

export const setPageTitle = (pageTitle) => {
    return {
        type: types.SET_TITLE,
        pageTitle
    }
}

export const setPagesToSing = (pagesToSing) => {
    return {
        type: types.SET_PAGESTOSING,
        pagesToSing
    }
}

export const setShowErrorModal = (show, title, message) => {
    return {
        type: types.SET_SHOW_ERROR_MODAL,
        show, title, message
    }
}