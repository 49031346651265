import { setLoading, setShowErrorModal } from '../General/actions';
import * as types from './types';
import { API_URL } from '../../config';
import { getAllStudies, getAllPreloadStudies, updateStudyOrder } from '../Studies/actions';
import { logout } from "../Login/actions";

export const getUsersSuccess = (users) => {
    return {
        type: types.GET_USERS,
        users,
    };
}

export const getUsers = () => async (dispatch, getState) => {
    dispatch(setLoading(true))
    const token = JSON.parse(localStorage.getItem('user')).token
    try {
        const response = await fetch(`${API_URL}/user/users`, {
            headers: { 'token': token },
            method: 'GET',
        });
        if (response.ok) {
            const jsonResponse = await response.json();
            if (jsonResponse.status === 1) {
                const users = jsonResponse.data
                dispatch(getUsersSuccess(users))
            } else {
                dispatch(setShowErrorModal(true, "Error", jsonResponse.message))
            }
        } else if (response.status === 401) {
            dispatch(logout())
            dispatch(setShowErrorModal(true, "Error", "Expiro su sesion, por favor ingrese nuevamente"));
        } else {
            dispatch(setShowErrorModal(true, "Error", "Ocurrió un error procesando la solicitud"));
        }
    } catch (error) {
        dispatch(setShowErrorModal(true, "Error", "Ocurrió un error procesando la solicitud"));
    } finally {
        dispatch(setLoading(false));
    }
}

const updateUserStore = (user, token) => {
    return {
        type: types.UPDATE_USER,
        user,
        token
    };
}

export const updateUser = (user, isAdmin) => async (dispatch, getState) => {
    dispatch(setLoading(true))
    const token = JSON.parse(localStorage.getItem('user')).token
    try {
        const response = await fetch(`${API_URL}/user/update`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'token': token,
            },
            body: JSON.stringify(user)
        });
        if (response.ok) {
            const jsonResponse = await response.json();
            if (jsonResponse.status === 1) {
                if (isAdmin) {
                    dispatch(getUsers())
                    dispatch(setShowErrorModal(true, "Exito", "Usuario Actualizado exitosamente"))
                }
                else {
                    dispatch(updateUserStore(user, token))
                    dispatch(setShowErrorModal(true, "Exito", "Usuario Actualizado exitosamente"))
                }
            } else {
                dispatch(setShowErrorModal(true, "Error", jsonResponse.message))
            }
        } else if (response.status === 401) {
            dispatch(logout())
            dispatch(setShowErrorModal(true, "Error", "Expiro su sesion, por favor ingrese nuevamente"));
        } else {
            dispatch(setShowErrorModal(true, "Error", "Ocurrió un error procesando la solicitud"));
        }
    } catch (error) {
        dispatch(setShowErrorModal(true, "Error", "Ocurrió un error procesando la solicitud"));
    } finally {
        dispatch(setLoading(false));
    }
}

const getStudiesSuccess = (studies) => {
    return {
        type: types.GET_STUDIES,
        studies,
    };
}


export const getUserStudies = (user) => async (dispatch, getState) => {
    dispatch(setLoading(true))
    const token = JSON.parse(localStorage.getItem('user')).token
    try {
        const response = await fetch(`${API_URL}/user_pdfs/?user_id=${user.id}`, {
            headers: { 'token': token },
            method: 'GET',
        });
        if (response.ok) {
            const jsonResponse = await response.json();
            if (jsonResponse.status === 1) {
                dispatch(getStudiesSuccess(jsonResponse.data))
            } else {
                dispatch(setShowErrorModal(true, "Error", jsonResponse.message))
            }
        } else if (response.status === 401) {
            dispatch(logout())
            dispatch(setShowErrorModal(true, "Error", "Expiro su sesion, por favor ingrese nuevamente"));
        } else {
            dispatch(setShowErrorModal(true, "Error", "Ocurrió un error procesando la solicitud"));
        }
    } catch (error) {
        dispatch(setShowErrorModal(true, "Error", "Ocurrió un error procesando la solicitud"));
    } finally {
        dispatch(setLoading(false));
    }
}

export const setCost = (pdf_id, amount, user, startDate, endDate) => async (dispatch, getState) => {
    dispatch(setLoading(true))
    const data = {
        pdf_id: pdf_id,
        amount: amount,
    }
    const token = JSON.parse(localStorage.getItem('user')).token
    try {
        const response = await fetch(`${API_URL}/preload_studies/set_cost`, {
            method: 'POST',
            headers: {
                'token': token,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data)
        });
        if (response.ok) {
            const jsonResponse = await response.json();
            if (jsonResponse.status === 1) {
                dispatch(getAllPreloadStudies(startDate, endDate))
                dispatch(setShowErrorModal(true, "Exito", "Costo registrado exitosamente"))
            } else {
                dispatch(setShowErrorModal(true, "Error", jsonResponse.message))
            }
        } else if (response.status === 401) {
            dispatch(logout())
            dispatch(setShowErrorModal(true, "Error", "Expiro su sesion, por favor ingrese nuevamente"));
        } else {
            dispatch(setShowErrorModal(true, "Error", "Ocurrió un error procesando la solicitud"));
        }
    } catch (error) {
        dispatch(setShowErrorModal(true, "Error", "Ocurrió un error procesando la solicitud"));
    } finally {
        dispatch(setLoading(false));
    }
}

export const setOrder = (pdf_id, val) => async (dispatch, getState) => {
    dispatch(setLoading(true))
    const data = {
        pdf_id: pdf_id,
        val: val
    }
    const token = JSON.parse(localStorage.getItem('user')).token
    try {
        const response = await fetch(`${API_URL}/preload_studies/set_order`, {
            method: 'POST',
            headers: {
                'token': token,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data) 
        });
        if (response.ok) {
            const jsonResponse = await response.json();
            if (jsonResponse.status === 1) {
                //dispatch(updateStudyOrder(pdf_id));
                dispatch(setShowErrorModal(true, "Exito", "Orden registrada exitosamente"))
            } else {
                dispatch(setShowErrorModal(true, "Error", jsonResponse.message))
            }
        } else if (response.status === 401) {
            dispatch(logout())
            dispatch(setShowErrorModal(true, "Error", "Expiro su sesion, por favor ingrese nuevamente"));
        } else {
            dispatch(setShowErrorModal(true, "Error", "Ocurrió un error procesando la solicitud"));
        }
    } catch (error) {
        dispatch(setShowErrorModal(true, "Error", "Ocurrió un error procesando la solicitud"));
    } finally {
        dispatch(setLoading(false));
    }
}

export const setPayment = (pdf_id, amount, user, startDate, endDate) => async (dispatch, getState) => {
    dispatch(setLoading(true))
    const data = {
        pdf_id: pdf_id,
        amount: amount,
    }
    const token = JSON.parse(localStorage.getItem('user')).token
    try {
        const response = await fetch(`${API_URL}/user_pdfs/set_payment`, {
            method: 'POST',
            headers: {
                'token': token,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data)
        });
        if (response.ok) {
            const jsonResponse = await response.json();
            if (jsonResponse.status === 1) {
                if (user) {
                    dispatch(getAllPreloadStudies(startDate, endDate))
                } else {
                    dispatch(getAllStudies(startDate,endDate))
                }
                dispatch(setShowErrorModal(true, "Exito", "Pago registrado exitosamente"))
            } else {
                dispatch(setShowErrorModal(true, "Error", jsonResponse.message))
            }
        } else if (response.status === 401) {
            dispatch(logout())
            dispatch(setShowErrorModal(true, "Error", "Expiro su sesion, por favor ingrese nuevamente"));
        } else {
            dispatch(setShowErrorModal(true, "Error", "Ocurrió un error procesando la solicitud"));
        }
    } catch (error) {
        dispatch(setShowErrorModal(true, "Error", "Ocurrió un error procesando la solicitud"));
    } finally {
        dispatch(setLoading(false));
    }
}

export const setSignature = (pdf_id, size) => async (dispatch, getState) => {
    dispatch(setLoading(true))
    const data = {
        pdf_id: pdf_id,
        size: size,
    }
    const token = JSON.parse(localStorage.getItem('user')).token
    try {
        const response = await fetch(`${API_URL}/user_pdfs/set_signature`, {
            method: 'POST',
            headers: {
                'token': token,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data)
        });
        if (response.ok) {
            const jsonResponse = await response.json();
            if (jsonResponse.status === 1) {
                dispatch(setShowErrorModal(true, "Exito", "Firmado Exitosamente"))
            } else {
                dispatch(setShowErrorModal(true, "Error", jsonResponse.message))
            }
        } else if (response.status === 401) {
            dispatch(logout())
            dispatch(setShowErrorModal(true, "Error", "Expiro su sesion, por favor ingrese nuevamente"));
        } else {
            dispatch(setShowErrorModal(true, "Error", "Ocurrió un error procesando la solicitud"));
        }
    } catch (error) {
        dispatch(setShowErrorModal(true, "Error", "Ocurrió un error procesando la solicitud"));
    } finally {
        dispatch(setLoading(false));
    }
}

export const setPublishUrgency = (startDate,endDate) => async (dispatch, getState) => {
    dispatch(setLoading(true))
    const token = JSON.parse(localStorage.getItem('user')).token
    try {
        const response = await fetch(`${API_URL}/user_pdfs/load_pdf_from_nobilis`, {
            method: 'POST',
            headers: {
                'token': token,
                'Content-Type': 'application/json',
            }
        });
        if (response.ok) {
            const jsonResponse = await response.json();
            if (jsonResponse.status === 1) {
                dispatch(setShowErrorModal(true, "Exito", "Agregados Exitosamente"))
                dispatch(getAllStudies(startDate,endDate))
            } else {
                dispatch(setShowErrorModal(true, "Error", jsonResponse.message))
            }
        } else if (response.status === 401) {
            dispatch(logout())
            dispatch(setShowErrorModal(true, "Error", "Expiro su sesion, por favor ingrese nuevamente"));
        } else {
            dispatch(setShowErrorModal(true, "Error", "Ocurrió un error procesando la solicitud"));
        }
    } catch (error) {
        dispatch(setShowErrorModal(true, "Error", "Ocurrió un error procesando la solicitud"));
    } finally {
        dispatch(setLoading(false));
    }
}

export const setNewOrders = (file, startDate, endDate) => async (dispatch, getState) => {
    dispatch(setLoading(true))
    const token = JSON.parse(localStorage.getItem('user')).token
    const formData = new FormData();
    formData.append('file', file);
    try {
        const response = await fetch(`${API_URL}/preload_studies/load_orders`, {
            method: 'POST',
            headers: {
                'token': token,
            },
            body: formData
        });
        if (response.ok) {
            const jsonResponse = await response.json();
            if (jsonResponse.status === 1) {
                dispatch(setShowErrorModal(true, "Exito", "Agregados Exitosamente"))
                dispatch(getAllPreloadStudies(startDate, endDate))
            } else {
                dispatch(setShowErrorModal(true, "Error", jsonResponse.message))
            }
        } else if (response.status === 401) {
            dispatch(logout())
            dispatch(setShowErrorModal(true, "Error", "Expiro su sesion, por favor ingrese nuevamente"));
        } else {
            dispatch(setShowErrorModal(true, "Error", "Ocurrió un error procesando la solicitud"));
        }
    } catch (error) {
        dispatch(setShowErrorModal(true, "Error", "Ocurrió un error procesando la solicitud"));
    } finally {
        dispatch(setLoading(false));
    }
}

export const setReloadPdf = (id, sing) => async (dispatch, getState) => {
    dispatch(setLoading(true))
    const token = JSON.parse(localStorage.getItem('user')).token
    try {
        const response = await fetch(`${API_URL}/user_pdfs/download_pdf_by_filename?pdf_filename=${id}&sing=${sing}`, {
            method: 'GET',
            headers: {
                'token': token,
                'Content-Type': 'application/json',
            }
        });
        if (response.ok) {
            const jsonResponse = await response.json();
            if (jsonResponse.status === 1) {
                dispatch(setShowErrorModal(true, "Exito", "Recargado Exitosamente"))
            } else {
                dispatch(setShowErrorModal(true, "Error", jsonResponse.message))
            }
        } else if (response.status === 401) {
            dispatch(logout())
            dispatch(setShowErrorModal(true, "Error", "Expiro su sesion, por favor ingrese nuevamente"));
        } else {
            dispatch(setShowErrorModal(true, "Error", "Ocurrió un error procesando la solicitud"));
        }
    } catch (error) {
        dispatch(setShowErrorModal(true, "Error", "Ocurrió un error procesando la solicitud"));
    } finally {
        dispatch(setLoading(false));
    }
}

export const setFindPdf = (id) => async (dispatch, getState) => {
    dispatch(setLoading(true))
    const token = JSON.parse(localStorage.getItem('user')).token
    try {
        const response = await fetch(`${API_URL}/preload_studies/load_pdf_by_filename?pdf_filename=${id}`, {
            method: 'GET',
            headers: {
                'token': token,
                'Content-Type': 'application/json',
            }
        });
        if (response.ok) {
            const jsonResponse = await response.json();
            if (jsonResponse.status === 1) {
                dispatch(setShowErrorModal(true, "Exito", jsonResponse.message))
            } else {
                dispatch(setShowErrorModal(true, "Error", jsonResponse.message))
            }
        } else if (response.status === 401) {
            dispatch(logout())
            dispatch(setShowErrorModal(true, "Error", "Expiro su sesion, por favor ingrese nuevamente"));
        } else {
            dispatch(setShowErrorModal(true, "Error", "Ocurrió un error procesando la solicitud"));
        }
    } catch (error) {
        dispatch(setShowErrorModal(true, "Error", "Ocurrió un error procesando la solicitud"));
    } finally {
        dispatch(setLoading(false));
    }
}

export const changePassword = (username, isAdmin, new_password, old_password) => async (dispatch, getState) => {
    dispatch(setLoading(true))
    const data = {
        username: username,
        new_password: new_password,
    }
    if (!isAdmin) {
        data.old_password = old_password
    }
    const token = JSON.parse(localStorage.getItem('user')).token
    try {
        const response = await fetch(`${API_URL}/auth/change_password`, {
            method: 'POST',
            headers: {
                'token': token,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data)
        });
        if (response.ok) {
            const jsonResponse = await response.json();
            if (jsonResponse.status === 1) {
                dispatch(setShowErrorModal(true, "Exito", "Contraseña Actualizada exitosamente"))
            } else {
                dispatch(setShowErrorModal(true, "Error", jsonResponse.message))
            }
        } else if (response.status === 401) {
            dispatch(logout())
            dispatch(setShowErrorModal(true, "Error", "Expiro su sesion, por favor ingrese nuevamente"));
        } else {
            dispatch(setShowErrorModal(true, "Error", "Ocurrió un error procesando la solicitud"));
        }
    } catch (error) {
        dispatch(setShowErrorModal(true, "Error", "Ocurrió un error procesando la solicitud"));
    } finally {
        dispatch(setLoading(false));
    }
}

export const fetchPDF = (pdf_id) => async (dispatch, getState) => {
    dispatch(setLoading(true));
    const token = JSON.parse(localStorage.getItem('user')).token;

    try {
        const response = await fetch(`${API_URL}/user_pdfs/get_pdf_by_id?pdf_id=${pdf_id}`, {
            headers: { 'token': token, 'Cache-Control': 'no-store', },
            method: 'GET',
        });

        if (response.ok) {
            const blob = await response.blob();
            const pdfUrl = URL.createObjectURL(blob);

            dispatch(setLoading(false));

            // Abre una nueva ventana o pestaña con el PDF
            const newWindow = window.open(pdfUrl, '_blank');
            if (!newWindow) {
                // Manejar el caso en que el navegador bloquea la ventana emergente
                dispatch(setShowErrorModal(true, "Error", "El navegador bloqueó la ventana emergente"));
            }
        } else {
            if (response.status === 404) {
                // Manejar el caso en que el PDF no existe (código 404)
                dispatch(setShowErrorModal(true, "Error", "El PDF no existe"));
            } else {
                const jsonResponse = await response.json();
                dispatch(setShowErrorModal(true, "Error", jsonResponse.message || "Ocurrió un error procesando la solicitud"));
            }
        }
    } catch (error) {
        dispatch(setShowErrorModal(true, "Error", "Ocurrió un error procesando la solicitud"));
    }

    dispatch(setLoading(false));
};


