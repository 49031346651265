import styled from "styled-components";

export const InputListContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 400px;
  margin: 20px;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 5px;
  background-color: #f9f9f9;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
  `;

export const ButtonsContainer = styled.div`
  display: flex;
  max-width: 400px;
  width: 100%;
  justify-content: space-between;
  margin: 10px 40px;
  `;

export const InputLabelContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: start;
  `;
export const Label = styled.div`
  width: 100px;
  `;