import styled from "styled-components";

export const Container = styled.div`
  margin-top: 100px;
`

export const TextContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: #000;
  text-align: center;
  font-family: Montserrat;
  font-size: 24px;
  font-style: italic;
  font-weight: 300;
  line-height: normal;
  letter-spacing: 1.2px;
`

export const ImagesContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 50px;
`;

export const Image = styled.img`
  width: calc(33.33% - 20px); /* Ajusta el tamaño de la imagen según tus necesidades */
  margin: 0 10px; /* Ajusta el margen entre las imágenes según tus necesidades */
`;
