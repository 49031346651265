import React from "react";
import RoundedImage from "../../../../../../commons/RoundedImage";
import { Container, NameItem, NameList, SpaceBetweenLists, TextContainer, Title } from "./styled";

const Section = ({ title, names, imageUrl }) => {
  return (
    <Container>
      <TextContainer>
        <Title>{title}</Title>
        <NameList>
          {names.map((name, index) => (
            <NameItem key={index}>{name}</NameItem>
          ))}
        </NameList>
      </TextContainer>
      <SpaceBetweenLists />
      <RoundedImage height={"300px"} width={"300px"} imageUrl={imageUrl} />
      
    </Container>
  );
}

export default Section;
