import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #000;
  text-align: center;
  font-family: Montserrat;
  font-size: 24px;
  font-style: italic;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 1.2px;
`;

export const Title = styled.h2`
  background-color: #29166F;
  text-transform: uppercase;
  color: #fff;
  padding: 0.825rem;
  margin-bottom: 1.8rem; 
  text-align: center;
  width: 20rem; 
  font-family: Montserrat;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 1.2px;
`;

export const NameList = styled.div`
  margin-top: 1rem; /* 10px dividido por 16px */
  text-align: center;
`;

export const NameItem = styled.p`
  margin-bottom: 1rem; 
  color: #000;
  text-align: center;
  font-family: Montserrat;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 1.2px;
`;

export const SpaceBetweenLists = styled.div`
  width: 15rem;
`;
