import styled from "styled-components";
import { colors } from "../../../../../../../utils/colors";

export const BoxContainer = styled.div`
  margin: 40px 100px;
  display: flex;
  border-radius: 50px;
  max-width: 750px;
  padding: 10px;
  background-color: #2C2A51;
  @media (max-width: 768px) {
    margin: 4px 10px;
  }
`;

export const Title = styled.h1`
  margin-left: 30px;
  color: ${colors["text-white"]};
  margin-bottom: 30px;
  font-family: Montserrat;
  font-size: 32px;
  font-style: italic;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 3.2px;
`;

export const ContentContainer = styled.div`
  display: flex;
  flex: 1.5;
  flex-direction: column;
`;

export const TextContainer = styled.div`
  color: ${colors["text-white"]};
  padding: 10px 40px;
  max-width: 700px;
  font-size: 20px;
  margin-bottom: 60px; /* Ajusta el margen inferior para que el texto arranque más arriba */
  font-family: Montserrat;
  font-style: italic;
  font-weight: 300;
  line-height: normal;
  letter-spacing: 2px;
  @media (max-width: 768px) {
    padding: 5px 10px;
  }
`;
