import React from "react";

const BackgroundSVGLeft2 = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="269" height="1207" viewBox="0 0 269 1207" fill="none">
            <path d="M262 707.267C313.76 895.477 55.4709 1206.5 -144.029 1206.5L-144.029 915.5L-233 707.267L-233 -5.01573e-05H-144.029C131.459 37.432 121.757 121.061 64.1331 191C30.4808 240.088 -7.5919 539.896 29.1732 615.894C95.5 753 221.5 560 262 707.267Z" fill="#29166F" fillOpacity="0.7" />
        </svg>

    );
};

export default BackgroundSVGLeft2;


