import React, { useState } from "react";
//import { useSelector } from 'react-redux';
import { /* ModalSelect */ ModalButtons, ModalContainer, ModalOverlay, ModalText } from "./styled";
import { BasicButton } from "../../../../Button";
import { Input } from "../../../../Input";
//import { selectPagesToSing } from '../../../../../../store/General/selectors';

const SignatureModal = ({ isOpen, onAccept, onClose }) => {
  //const [inputValue, setInputValue] = useState(0);
  //const [inputNumberValue, setinputNumberValue] = useState(1);
  const [inputSizeValue, setinputSizeValue] = useState([]);
  //const pages = useSelector(selectPagesToSing);

  /* const handleSelectChange = (event) => {
    setInputValue(event.target.value);
    console.log(pages)
    console.log(event.target.value)
    const selectedElement = pages.find((element) => element.id == event.target.value);
    setinputNumberValue(selectedElement.page_number)
    setinputSizeValue(selectedElement.size)
  };

  const handleInputNumberChange = (event) => {
    setinputNumberValue(event.target.value);
  }; */

  const handleInputSizeChange = (event) => {
    const inputValue = event.target.value;
    const resultArray = inputValue.split(',');
    setinputSizeValue(resultArray);
  };

  const handleAccept = () => {
    onAccept(inputSizeValue);
    setinputSizeValue(null)
    onClose();
  };

  const handleCancel = () => {
    setinputSizeValue(null)
    onClose();
  };

  if (!isOpen) {
    return null;
  }

  return (
    <ModalOverlay>
      <ModalContainer>
        {/* <ModalText>Seleccione pagina a firmar</ModalText>
        <ModalSelect onChange={handleSelectChange} value={inputValue}>
              <option value="0">
                Seleccione una pagina
              </option>
          {pages.map((page) => (
            // Only render options for pages where page.firmado is false
            !page.firmado && (
              <option key={page.id} value={page.id}>
                {page.sector}
              </option>
            )
          ))}
        </ModalSelect> 
        <ModalText>Seleccione numero de pagina</ModalText>
        <Input type="number" value={inputNumberValue} onChange={handleInputNumberChange} /> */}
        <ModalText>Opcional: Paginas a no firmar</ModalText>
        <Input type="text" onChange={handleInputSizeChange} />
        <ModalButtons>
          <BasicButton onClick={handleCancel}>Cancelar</BasicButton>
          <BasicButton onClick={handleAccept}>Aceptar</BasicButton>
        </ModalButtons>
      </ModalContainer>
    </ModalOverlay>
  );
};

export default SignatureModal;