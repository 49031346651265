import { InputComponent } from './styled'

export const Input = ({ type, name, placeholder, value, onChange, readOnly }) => {
    return (
        <InputComponent
            type={type}
            name={name}
            placeholder={placeholder}
            value={value}
            onChange={onChange}
            readOnly={readOnly}
        />
    );
};