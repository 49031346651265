import styled from "styled-components";

export const Container = styled.div`
    margin-top: 100px;
    display: flex;
    flex-direction: column;
`

export const TextContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: #000;
  text-align: center;
  font-family: Montserrat;
  font-size: 24px;
  font-style: italic;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 1.2px;
`

export const SpaceBetweenSections = styled.div`
  height: 7rem;
`;