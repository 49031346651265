import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const ListIconsContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    @media (max-width: 768px) {
      flex-direction: column;
    }
`

export const IconContainer = styled.div`
    display: flex;
    margin: 30px 0;
`

export const LeftHalf = styled.div`
  flex: 1;
`;

export const RightHalf = styled.div`
  flex: 1;
`;


export const TextContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  margin-top: 35px;
  color: #000;
  font-family: Montserrat;
  font-size: 24px;
  font-style: italic;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 1.2px;
`

export const SpaceBetweenLists = styled.div`
  width: 15rem;
`;