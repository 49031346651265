import styled from "styled-components";
import { colors } from "../../../../../utils/colors";

export const Container = styled.div`
`

export const FooterContainer = styled.footer`
  background-color: ${colors.footer};
  color: #fff;
  padding: 20px;
  margin-top: 50px;
  margin-bottom: 30px;
  display: flex;
  justify-content: center;
`;

export const FooterContent = styled.div`
  display: flex;
  justify-content: space-between;
  max-width: 1200px; /* Ancho máximo para limitar el contenido */
  width: 100%; /* Ocupar todo el ancho disponible */
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const FooterBlock = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 10px;
`;

export const FooterTitle = styled.h2`
  margin-bottom: 10px;
  color: #FFF;
  text-align: center;
  font-family: Montserrat;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 26px; /* 108.333% */
`;

export const FooterText = styled.p`
  color: #FFF;
  text-align: center;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 26px; /* 162.5% */
`;

export const AdditionalText = styled.p`
`

export const Link = styled.a`
    color: ${colors["text-white"]};
`

export const CenteredTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 40px;
`;

export const CenteredText = styled.p`
  text-align: center;
  font-size: 20px; /* Ajusta el tamaño de fuente según tus necesidades */
  color: #000;
  text-align: center;
  font-family: Montserrat;
  font-style: italic;
  font-weight: 300;
  line-height: 26px; /* 130% */
`;

export const LinkBeforeFooter = styled.a`
    color: ${colors.black}
`

export const IconTextContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: ${props => props.marginTop || "5px"};
  gap: 5px;
`