import React from "react";

const BackgroundSVGLeft = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="930" height="720" viewBox="0 0 930 720" fill="none">
        <path d="M276.632 140.098C130.674 154.095 31.3947 43.8649 0 -13L930 -9.17256V776H826.129C504.504 734.445 558.646 568.771 625.92 491.129C665.208 436.633 736.25 305.334 706.111 216.1C668.438 104.557 459.08 122.601 276.632 140.098Z" fill="#29166F" fillOpacity="0.5"/>
    </svg>
  );
};

export default BackgroundSVGLeft;