import { API_URL } from '../../config';
import { setLoading, setShowErrorModal } from '../General/actions';
import * as types from './types';


const loginSuccess = (user, token) => {
    return {
        type: types.LOGIN_SUCCESS,
        user,
        token,
    };
}

export const login = (username, password) => {
    return async (dispatch) => {
        dispatch(setLoading(true))
        try {
            const response = await fetch(`${API_URL}/auth/login?username=${username}&password=${password}`, {
                method: 'GET',
            });
            if (response.ok) {
                const jsonResponse = await response.json();
                if (jsonResponse.status === 1) {
                    const token = jsonResponse.data.token
                    const user = jsonResponse.data.user
                    dispatch(loginSuccess(user, token))
                } else {
                    dispatch(setShowErrorModal(true, "Error", jsonResponse.message))
                }
            }
        } catch (error) {
            dispatch(setShowErrorModal(true, "Error", "Ocurrió un error procesando la solicitud"))
        }
        dispatch(setLoading(false))
    };
};

export const logout = () => {
    return {
        type: types.LOGOUT,
    }
}


export const signIn = (name, username, password, email, phone) => {
    return async (dispatch) => {
        dispatch(setLoading(true))
        const userData = {
            name: name,
            user: username,
            password: password,
            email: email,
            phone: phone
        }
        try {
            const response = await fetch(`${API_URL}/auth/signup`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(userData),
            });
            if (response.ok) {
                const jsonResponse = await response.json();
                if (jsonResponse.status === 1) {
                    const token = jsonResponse.data.token
                    dispatch(loginSuccess(username, token))
                } else {
                    dispatch(setShowErrorModal(true, "Error", jsonResponse.message))
                }
            }
        } catch (error) {
            dispatch(setShowErrorModal(true, "Error", "Ocurrió un error procesando la solicitud"))
        }
        dispatch(setLoading(false))
    };
}