import { BasicButton } from "../../commons/Button";
import ColumnFilter from "./columnFilter";


export const columns = (payButtonAction, costButtonAction, reloadButtonAction, orderButtonAction, isAdmin) => {
  return [
    { Header: 'Paciente', accessor: 'pacient', Filter: ColumnFilter },
    { Header: 'Fecha', accessor: 'fecha', Filter: ColumnFilter },
    { Header: 'Estado', accessor: 'estado', Filter: ColumnFilter },
    { Header: 'Orden', accessor: 'nro', Filter: ColumnFilter },
    { Header: 'Costo', accessor: 'costo', Filter: ColumnFilter },
    { Header: 'Pago', accessor: 'amount', Filter: ColumnFilter },
    {
      Header: '',
      hideHeader: true,
      id: 'paybutton',
      Cell: ({ row }) => (
        isAdmin ? (
          <BasicButton onClick={() => payButtonAction(row.original.nro)}>Pagar</BasicButton>
        ) : 'No'
      ),
    },
    {
      Header: '',
      hideHeader: true,
      id: 'costButton',
      Cell: ({ row }) => (
        (isAdmin) ? (
          <BasicButton onClick={() => costButtonAction(row.original.nro)}>Costo</BasicButton>
        ) : null
      ),
    },
    {
        Header: '',
        hideHeader: true,
        id: 'orderButton',
        Cell: ({ row }) => (
            row.original.orden == 0 ? 
            (isAdmin ? (<BasicButton onClick={() => orderButtonAction(row.original.nro, 1)}>Orden</BasicButton>) : 'No') 
            : ((isAdmin ? (<BasicButton onClick={() => orderButtonAction(row.original.nro, 0)}>No Tiene Orden</BasicButton>) : 'Si'))
        ),
    },
    {
        Header: '',
        hideHeader: true,
        id: 'findButton',
        Cell: ({ row }) => (
          (isAdmin) ? (
            <BasicButton onClick={() => reloadButtonAction(row.original.nro)}>Buscar</BasicButton>
          ) : null
        ),
    },
  ]
};