import styled from "styled-components";

export const Image = styled.img`
  margin: 40px;
  width: ${props => props.width || "360px"};
  height: ${props => props.height || "360px"};
  border-radius: 50%;
  @media (max-width: 768px) {
    margin:0;
  }
`;
