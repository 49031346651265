import React from 'react';

const ColumnFilter = ({ column }) => {
  const { setFilter } = column;

  return (
    <input
      onChange={e => setFilter(e.target.value)} // Aplica el filtro a todas las columnas
      placeholder={`Filtrar...`}
    />
  );
};

export default ColumnFilter;
