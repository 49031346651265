import React from "react";

const BackgroundSVGLeft = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="239" height="861" viewBox="0 0 239 861" fill="none">
      <path d="M171.001 690.602C26.0007 971.602 61.0004 819.602 -138.499 819.602L-49.5279 690.602L-138.499 482.369L-341.999 238.102L-107.499 20.1019C123.674 -56.3981 40.0007 110.102 206.501 110.102C307.501 383.102 139.584 405.142 77.1738 461.996C-63.999 590.603 227.246 581.603 171.001 690.602Z" fill="#29166F" />
    </svg>
  );
};

export default BackgroundSVGLeft;
