import React from "react";

const BackgroundSVGLeft = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="232"
      height="1458"
      viewBox="0 0 232 1458"
      fill="none"
    >
      <path
        d="M159.939 435.483C-84.0608 -327.017 397.226 134.791 397.226 176.891L501.099 581.618L331.596 1286.12L353.939 1314.48C261.654 1681.35 68.1645 1236.63 135.439 1158.98C174.727 1104.49 327.784 972 90.999 894C-168.386 808.555 215.8 610.048 159.939 435.483Z"
        fill="#29166F"
        fillOpacity="0.7"
      />
    </svg>

  );
};

export default BackgroundSVGLeft;


