import { initialState } from './initialState';
import * as types from './types';


const usersReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_USERS:
      return {
        ...state,
        users: action.users,
      };
    case types.GET_STUDIES:
      return {
        ...state,
        studies: action.studies,
      };
    case types.UPDATE_USER:
      localStorage.setItem('user', JSON.stringify({ user: action.user,  token: action.token }));
      return state;
    default:
      return state;
  }
};

export default usersReducer;
