import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  margin: 200px 0;
  @media (max-width: 768px) {
    flex-direction: column;
    margin: 20px 0;
  }
`;

export const ContainerWrapper = styled.div`
  position: relative;
  top: -50px;
  @media (max-width: 768px) {
    display: none;
  }
`;

export const InsideWrapper1 = styled.div`
  position: relative;
  z-index: 1;
`;

export const InsideWrapper2 = styled.div`
  position: absolute;
  top: 180px;
  left: 100px;
  z-index: 2;
`;