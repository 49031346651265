import styled from 'styled-components';

export const Button = styled.button`
  background-color: ${props => props.disabled ? '#ccc' : '#29166F'};
  color: ${props => props.disabled ? '#999' : 'white'};
  font-size: 1em;
  padding: 0.5em 1em;
  border-radius: 20px;
  border: none;
  cursor: ${props => props.disabled ? 'not-allowed' : 'pointer'};
  transition: background-color 0.3s ease;

  &:hover {
    background-color: ${props => props.disabled ? '#ccc' : '#0D47A1'};
  }

  &:active {
    background-color: ${props => props.disabled ? '#ccc' : '#0B3B8C'};
  }

  &:focus {
    outline: none;
  }
`;