import React from "react";
import { ModalContainer, ModalContent, ModalTitle, ModalText, CloseButton } from "./styled";
import CrossIcon from "../CrossIcon";

const Modal = ({ title, message, onClose }) => {
  return (
    <ModalContainer>
      <ModalContent>
        <CloseButton onClick={onClose}>
          <CrossIcon size={16} color="red"/>
        </CloseButton>
        <ModalTitle>{title}</ModalTitle>
        <ModalText>{message}</ModalText>
      </ModalContent>
    </ModalContainer>
  );
};

export default Modal;
