import { setLoading, setShowErrorModal } from '../General/actions';
import * as types from './types';
import { API_URL } from '../../config';
import { logout } from "../Login/actions";

const getStudiesSuccess = (studies) => {
    return {
        type: types.GET_ALL_STUDIES,
        studies,
    };
}

const getPreloadStudiesSuccess = (studies) => {
    return {
        type: types.GET_ALL_PRELOAD_STUDIES,
        studies,
    };
}

export const updateStudyOrder = (pdf_id) => {
    return {
        type: types.UPDATE_STUDY_ORDER,
        pdf_id
    };
}

export const getAllStudies = (startDate, endDate) => async (dispatch, getState) => {
    dispatch(setLoading(true))
    const token = JSON.parse(localStorage.getItem('user')).token
    try {
        const response = await fetch(`${API_URL}/user_pdfs/all?startDate=${startDate}&endDate=${endDate}`, {
            headers: {'token': token},
            method: 'GET',
        });
        if (response.ok) {
            const jsonResponse = await response.json();
            if (jsonResponse.status === 1) {
                const studies = jsonResponse.data
                dispatch(getStudiesSuccess(studies))
            } else {
                dispatch(setShowErrorModal(true, "Error", jsonResponse.message))
            }
        } else if (response.status === 401) {
            dispatch(logout())
            dispatch(setShowErrorModal(true, "Error", "Expiro su sesion, por favor ingrese nuevamente"));
        } else {
            dispatch(setShowErrorModal(true, "Error", "Ocurrió un error procesando la solicitud"));
        }
    } catch (error) {
        dispatch(setShowErrorModal(true, "Error", "Ocurrió un error procesando la solicitud"));
    } finally {
        dispatch(setLoading(false));
    }
}

export const getAllPreloadStudies = (startDate, endDate) => async (dispatch, getState) => {
    dispatch(setLoading(true))
    const token = JSON.parse(localStorage.getItem('user')).token
    try {
        const response = await fetch(`${API_URL}/preload_studies/allPreloads?startDate=${startDate}&endDate=${endDate}`, {
            headers: {'token': token},
            method: 'GET',
        });
        if (response.ok) {
            const jsonResponse = await response.json();
            if (jsonResponse.status === 1) {
                const studies = jsonResponse.data
                dispatch(getPreloadStudiesSuccess(studies))
            } else {
                dispatch(setShowErrorModal(true, "Error", jsonResponse.message))
            }
        } else if (response.status === 401) {
            dispatch(logout())
            dispatch(setShowErrorModal(true, "Error", "Expiro su sesion, por favor ingrese nuevamente"));
        } else {
            dispatch(setShowErrorModal(true, "Error", "Ocurrió un error procesando la solicitud"));
        }
    } catch (error) {
        dispatch(setShowErrorModal(true, "Error", "Ocurrió un error procesando la solicitud"));
    } finally {
        dispatch(setLoading(false));
    }
}