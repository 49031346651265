import { initialState } from './initialState';
import * as types from './types';


const studiesReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_ALL_STUDIES:
      return {
        ...state,
        allStudies: action.studies,
      };
    case types.GET_ALL_PRELOAD_STUDIES:
      return {
        ...state,
        allPreloadStudies: action.studies,
      };
    case types.UPDATE_STUDY_ORDER:
      const pdf_id = action.pdf_id;
      const studyIndex = state.allPreloadStudies.findIndex(study => parseInt(study.nro) === parseInt(pdf_id));
      if (studyIndex !== -1) {
        const updatedStudies = [...state.allPreloadStudies];
        updatedStudies[studyIndex] = { ...updatedStudies[studyIndex], orden: 1 };
    
        return {
          ...state,
          allPreloadStudies: updatedStudies,
        };
      } else {
        return state; // No matching study found, return the state unchanged
      }
    default:
      return state;
  }
};

export default studiesReducer;
