import React from "react";
import { ImagesContainer, Image, Container, TextContainer } from "./styled";
import photo2 from "../../../../../images/nuevas/equipo1.jpg"
import photo3 from "../../../../../images/nuevas/equipo2.jpg"
import photo4 from "../../../../../images/nuevas/directoras.jpg"

const SecondBlock = () => {
    return (
        <Container>
            <TextContainer>
                Somos un equipo con amplia experiencia y alto compromiso profesional
            </TextContainer>
            <ImagesContainer>
                <Image src={photo2} alt="Imagen 1" />
                <Image src={photo4} alt="Imagen 2" />
                <Image src={photo3} alt="Imagen 3" />
            </ImagesContainer>
        </Container>
    )
}
export default SecondBlock;