import React from "react";
import IconBox from "./Components/IconBox";
import icon3 from "../../../../../images/icons/Frame 33.jpg";
import icon4 from "../../../../../images/icons/Frame 34.jpg";
import icon5 from "../../../../../images/icons/Frame 35.jpg";
import icon8 from "../../../../../images/icons/fabrica.jpeg";
import icon9 from "../../../../../images/icons/Frame 39.jpg";
import icon10 from "../../../../../images/icons/adn.png";
import icon11 from "../../../../../images/icons/patita.png";
import icon12 from "../../../../../images/icons/microscopio.png";
import icon13 from "../../../../../images/icons/bacteria.png";
import icon14 from "../../../../../images/icons/honguito.jpeg";
import { Container, IconContainer, LeftHalf, ListIconsContainer, RightHalf, SpaceBetweenLists, TextContainer } from "./styled";

const ServicesBlock = () => {
  return (
    <Container id="services">
      <TextContainer>
        Trabajamos con todas las obras sociales, y nuestros servicios principales constan de:
      </TextContainer>
      <ListIconsContainer>
        <LeftHalf>
          <IconContainer>
            <IconBox icon={icon12} title={"Hematologia y Hemostosia"} description={""} />
          </IconContainer>
          <IconContainer>
            <IconBox icon={icon3} title={"Quimica Clinica"} description={""} />
          </IconContainer>
          <IconContainer>
            <IconBox icon={icon9} title={"Inmunoserologia Endocrinologia"} description={""} />
          </IconContainer>
          <IconContainer>
            <IconBox icon={icon10} title={"Genetica y Analisis de Paternidad"} description={""} />
          </IconContainer>
          <IconContainer>
            <IconBox icon={icon4} title={"Analisis de Agua"} description={""} />
          </IconContainer>
        </LeftHalf>
        <SpaceBetweenLists />
        <RightHalf>
          <IconContainer>
            <IconBox icon={icon11} title={"Screening Neonatal"} description={""} />
          </IconContainer>
          <IconContainer>
            <IconBox icon={icon5} title={"Toxicologia"} description={""} />
          </IconContainer>
          <IconContainer>
            <IconBox icon={icon13} title={"Bacteriologia Clinica e Industrial"} description={""} />
          </IconContainer>
          <IconContainer>
            <IconBox icon={icon14} title={"Micologia / Parasitologia / Virologia"} description={""} />
          </IconContainer>
          <IconContainer>
            <IconBox icon={icon8} title={"Preocupacionales"} description={""} />
          </IconContainer>
        </RightHalf>
      </ListIconsContainer>
    </Container>

  )
}

export default ServicesBlock;