import React from "react";
import { BoxContainer, ContentContainer, TextContainer, Title } from "./styled";

const TextBox = ({ title, text1, text2 }) => {
    return (
        <BoxContainer>
            <ContentContainer>
                <Title>{title}</Title>
                <TextContainer>{text1}</TextContainer>
                <TextContainer>{text2}</TextContainer>
            </ContentContainer>
        </BoxContainer>
    );
};

export default TextBox;
