import React from "react";
import BackgroundSVGRight from "../BackgroundLeft";
import BackgroundSVGLeft from "../BackgroundRight";
import { BackgroundLeft, BackgroundRight, BackgroundStack, Wrapper } from "./styled";
import BackgroundSVGLeft2 from "../BackgroundLeft2";

const BackgroundWrapper = ({ children }) => {
    return (
        <Wrapper>
            <BackgroundStack>
                <BackgroundLeft>
                    <BackgroundSVGLeft2 />
                </BackgroundLeft>
                <BackgroundLeft>
                    <BackgroundSVGLeft />
                </BackgroundLeft>
            </BackgroundStack>
            {children}
            <BackgroundRight>
                <BackgroundSVGRight />
            </BackgroundRight>
        </Wrapper>
    );
};

export default BackgroundWrapper;
