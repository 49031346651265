import styled from "styled-components";
import { colors } from "../../../../../utils/colors";
import "@fontsource/montserrat"; // Defaults to weight 400
import "@fontsource/montserrat/400.css"; // Specify weight
import "@fontsource/montserrat/400-italic.css";

export const Container = styled.header`
  display: flex;
  align-items: center;
  height: 50px;
  padding: 0 32px;
  background-color: ${colors["header-background"]};
  color: ${colors.logo};

  @media (max-width: 768px) {
    display: none;
  }

`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: 32px;
  width: 100%;
`;

export const Button = styled.button`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  padding: 0 20px;
  border-radius: 20px;
  font-size: 16px;
  font-weight: 500;
  text-transform: uppercase;
  font-weight: bold;
  background-color: transparent;
  color: ${colors["header-text"]};
  border: none;
  cursor: pointer;
  color: #000;
  text-align: center;
  font-family: Montserrat;
  font-style: normal;
  line-height: 26px; /* 162.5% */

  &:hover {
    cursor: pointer;
  }
`;
