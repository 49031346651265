import React from "react";
import TextBox from "./Components/TextBox";
import photo from "../../../../../images/nuevas/arriba.jpeg"
import photo2 from "../../../../../images/nuevas/abajo.jpeg"
import { Container, ContainerWrapper, InsideWrapper1, InsideWrapper2 } from "./styled";
import RoundedImage from "../../../../commons/RoundedImage";

const MissionBlock = () => {
    return (
        <Container id="mission">
            <ContainerWrapper>
                <InsideWrapper1>
                    <RoundedImage imageUrl={photo} width={"300px"} height={"300px"}/>
                </InsideWrapper1>
                <InsideWrapper2>
                    <RoundedImage imageUrl={photo2} width={"300px"} height={"300px"}/>
                </InsideWrapper2>
            </ContainerWrapper>
            <TextBox
                imageUrl={photo}
                title={"Misión"}
                text1={"Nos enfocamos en brindar un servicio de excelencia para el cuidado \
            de la salud de nuestros pacientes y facilitar la toma de decisiones médicas \
            a través de una oportuna y correcta información."}
                text2={"Nuestro compromiso seguirá siendo desarrollar las acividades en un \
            marco ético y profesional asegurando la calidad de los resultados"} />
        </Container>
    )
}

export default MissionBlock