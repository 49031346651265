import { initialState } from './initialState';
import * as types from './types';


const loginReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.LOGIN_SUCCESS:
      localStorage.setItem('user', JSON.stringify({ user: action.user,  token: action.token }));
      return {
        ...state,
        loggedIn: true,
        loginFailed: false,
        username: action.user.username,
        user: action.user,
        token: action.token,
        isAdmin: action.user.role === 'admin',
      };
    case types.LOGIN_FAILED:
      localStorage.removeItem('user')
      return {
        ...state,
        loggedIn: false,
        username: '',
        token: '',
        loginFailed: true,
        isAdmin: false,
      };
    case types.LOGOUT:
      localStorage.removeItem('user')
      return {
        ...state,
        loggedIn: false,
        username: '',
        token: '',
        isAdmin: false,
      };
    default:
      return state;
  }
};

export default loginReducer;
