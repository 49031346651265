import React, { useState } from "react";
import { ModalButtons, ModalContainer, ModalOverlay, ModalText, InputButtonContainer} from "./styled";
import { BasicButton } from "../../../../Button";
import { Input } from "../../../../Input";

const ChangePasswordModal = ({ isOpen, onAccept, onClose, showOldPasswordInput }) => {
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');

  const handleInputChange = (event) => {
    switch (event.target.name) {
      case "old_p":
        setOldPassword(event.target.value);
        break;
      case "new_p":
        setNewPassword(event.target.value);
        break;
      default:
        break;
    }
  };

  const generateRandomPassword = () => {
    const randomPassword = Math.random().toString(36).slice(-8);
    setNewPassword(randomPassword);
  }

  const handleAccept = () => {
    onAccept(oldPassword, newPassword);
    setOldPassword('')
    setNewPassword('')
    onClose();
  };

  const handleCancel = () => {
    setOldPassword('')
    setNewPassword('')
    onClose();
  };

  if (!isOpen) {
    return null;
  }

  return (
    <ModalOverlay>
      <ModalContainer>
        {showOldPasswordInput &&
          (<div><ModalText>Contraseña anterior:</ModalText>
            <Input name="old_p" type="text" value={oldPassword} onChange={handleInputChange} />
          </div>)}
        <ModalText>Nueva contraseña:</ModalText>
        <InputButtonContainer>
          <Input name="new_p" type="text" value={newPassword} onChange={handleInputChange} />
          <a href="#" onClick={generateRandomPassword}>Generar</a>
        </InputButtonContainer>
        <ModalButtons>
          <BasicButton onClick={handleCancel}>Cancelar</BasicButton>
          <BasicButton onClick={handleAccept}>Aceptar</BasicButton>
        </ModalButtons>
      </ModalContainer>
    </ModalOverlay>
  );
};

export default ChangePasswordModal;