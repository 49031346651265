export const isLoggedIn = (state) => Boolean(localStorage.getItem("user"));

export const getUsername = (state) => state.login.username;

export const selectToken = (state) => JSON.parse(localStorage.getItem('user'))?.token;

export const loginError = (state) => state.login.loginFailed;

export const getUser = (state) => JSON.parse(localStorage.getItem('user'))?.user;

export const isAdmin = (state) => JSON.parse(localStorage.getItem('user'))?.user?.role === 'admin';