import React from "react";
import Section from "./Components/Section";
import { Container, SpaceBetweenSections, TextContainer } from "./styled";
import photo1 from "../../../../../images/nuevas/marceyisa.jpeg";
import photo2 from "../../../../../images/nuevas/bioquimica.jpg";
import photo3 from "../../../../../images/nuevas/tecnica.jpg";
import photo4 from "../../../../../images/nuevas/recepcion.jpg";
import BackgroundWrapper from "./Components/BackgroundWrapper";

const TeamBlock = () => {
  return (
    <BackgroundWrapper> {/* Wrap the entire content */}
      <Container id="team">
        <TextContainer>
          Nuestro equipo está conformado por:
        </TextContainer>
        <Section
          title={"Dirección técnica"}
          names={["Dra. Isabel Ginzo", "Dra. Marcela Castellani"]}
          imageUrl={photo1}
        />
        <SpaceBetweenSections />
        <Section
          title={"Área bioquímica"}
          names={["Dra. Marta Christiani", "Dra. Romanela Rinaldi"]}
          imageUrl={photo2}
        />
        <SpaceBetweenSections />
        <Section
          title={"Área técnica"}
          names={["Dra. Laura Sanguinetti", "Adriana Gracioso"]}
          imageUrl={photo3}
        />
        <SpaceBetweenSections />
        <Section
          title={"Área administrativa"}
          names={["Mariela Lasarte", "Esteban Gorriz"]}
          imageUrl={photo4}
        />
        <SpaceBetweenSections />
      </Container>
    </BackgroundWrapper>
  );
};

export default TeamBlock;
