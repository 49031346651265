import React, { useEffect } from 'react';
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { Provider, useSelector } from "react-redux"
import Home from "../Home";
import Login from "../Login";
import Landing from "../Landing"
import store from "../../../store";
import MainContainer from "../../commons/MainContainer";
import { getUser, isLoggedIn } from "../../../store/Login/selectors";
/* import SignIn from "../Signin";*/
import UsersTable from "../UsersTable";
import UserProfile from "../UserProfile";
import Studies from "../Studies";
import PreLoads from "../PreLoads";
import { initGA, logPageView } from '../../../analytics';

const PrivateRoute = ({ element: Element, ...rest }) => {
  const loggedIn = useSelector(isLoggedIn);
  return loggedIn ? (
    <Element />
  ) : (
    <Navigate to="/login" replace={true} />
  );
}

const AdminRoute = ({ element: Element, ...rest }) => {
  const loggedIn = useSelector(isLoggedIn);
  const user = useSelector(getUser)
  return loggedIn && user.role === 'admin' ? (
    <Element />
  ) : (
    <Navigate to="/login" replace={true} />
  );
}

const App = () => {
  useEffect(() => {
    initGA();
    logPageView();
    window.addEventListener('popstate', logPageView);
  }, []);
  return (
    <Provider store={store}>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Landing />} />
        </Routes>
      </BrowserRouter>

      <BrowserRouter>
        <MainContainer>
          <Routes>
            <Route path="/home" element={<PrivateRoute element={() => <Home />} />} />
            <Route path="/profile" element={<PrivateRoute element={() => <UserProfile />} />} />
            <Route path="/login" element={<Login />} />
            {/* <Route path="/signin" element={<SignIn />} /> */}
            {/* <Route path="/users" element={<UsersTable />} /> */}
            <Route path="/users" element={<AdminRoute element={() => <UsersTable />} />} />
            <Route path="/studies" element={<AdminRoute element={() => <Studies />} />} />
            <Route path="/preload" element={<AdminRoute element={() => <PreLoads />} />} />
          </Routes>
        </MainContainer>
      </BrowserRouter>
    </Provider>
  );
}


export default App;
