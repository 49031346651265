import styled from "styled-components";

export const Wrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const BackgroundStack = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  left: 0;
`;

export const BackgroundLeft = styled.div`
    margin-top: 30px;
  /* You can add any specific styling for each BackgroundLeft component here */
  @media (max-width: 768px) {
    display: none;
  }
`;

export const BackgroundRight = styled.div`
  position: absolute;
  right: 0;
  @media (max-width: 768px) {
    display: none;
  }
`;
