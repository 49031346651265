import React from "react";
import { Container, Description, SquareContainer, TextContainer, Title, ImgIcon } from "./styled";

const IconBox = ({ icon: Icon, title, description }) => {
    return (
      <Container>
        <SquareContainer>
          <ImgIcon src={Icon} alt="{title}" />
        </SquareContainer>
        <TextContainer>
          <Title>{title}</Title>
          <Description>{description}</Description>
        </TextContainer>
      </Container>
    );
  };
  
  export default IconBox;