import React from "react";
import TextBox from "./Components/TextBox";
import photo from "../../../../../images/nuevas/mision.jpeg"
import { Container } from "./styled";

const AboutBlock = () => {
    return (
        <Container id="about">
            <TextBox 
            imageUrl={photo} 
            title={"Nosotros"} 
            text1={"Un 2 de agosto del año 2006 poníamos en marcha el laboratorio \
            de análisis clínicos y bacteriológicos, concretando el sueño de Isabel y \
            Marcela, dos compañeras del Hospital Dr. Posadas de Saladillo."}
            text2={"Iniciamos ese camino junto a Marta y Juan Pablo poniendo todo de \
            nosotros para brindar un servicio eficiente con una atención cordial y personalizada. \
            Se nos presentaron muchos desafíos que fuimos enfrentando con el coraje que da el entusiasmo \
            por hacer lo que nos gusta y así... crecimos."}
            text3={"Sea cual sea el contexto, apsotamos a ampliar infraestructura, modernizar \
            equipamiento y capacitarnos con el único objetivo de superarnos día a día."} />
        </Container>
    )
}

export default AboutBlock