import React, { useState } from "react";
import { ModalButton, ModalButtons, ModalContainer, ModalOverlay, ModalText } from "./styled";
import { BasicButton } from "../../../../Button";
import { Input } from "../../../../Input";

const PaymentModal = ({ isOpen, onAccept, onClose, text }) => {
  const [inputValue, setInputValue] = useState('');

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  const handleAccept = () => {
    onAccept(inputValue);
    setInputValue('')
    onClose();
  };

  const handleCancel = () => {
    setInputValue('')
    onClose();
  };

  if (!isOpen) {
    return null;
  }

  return (
    <ModalOverlay>
      <ModalContainer>
        <ModalText>{text}</ModalText>
        <Input type="number" value={inputValue} onChange={handleInputChange} />
        <ModalButtons>
          <BasicButton onClick={handleCancel}>Cancelar</BasicButton>
          <BasicButton onClick={handleAccept}>Aceptar</BasicButton>
        </ModalButtons>
      </ModalContainer>
    </ModalOverlay>
  );
};

export default PaymentModal;