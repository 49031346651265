import { configureStore } from '@reduxjs/toolkit';
import loginReducer from './store/Login/reducer';
import thunk from 'redux-thunk';
import generalReducer from './store/General/reducer';
import usersReducer from './store/Users/reducer';
import studiesReducer from './store/Studies/reducer';

const store = configureStore({
  middleware: [thunk],
  reducer: {
    login: loginReducer,
    general: generalReducer,
    users: usersReducer,
    studies: studiesReducer,
  },
  // Other store configuration options go here
});

export default store;