import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Loading from "../Loading";
import { Container, SidebarAndContentContainer, SectionButtonWrapper, SectionTitle } from "./styled";
import { selectLoading, selectModalMessage, selectModalTitle, selectShowErrorModal } from "../../../store/General/selectors";
import { isLoggedIn } from "../../../store/Login/selectors";
import Modal from "../ErrorModal";
import { setShowErrorModal } from "../../../store/General/actions";
import Header from "../Header";
import { selectPageTitle } from '../../../store/General/selectors';

const MainContainer = ({ children }) => {

    const loading = useSelector(selectLoading);
    const showErrorModal = useSelector(selectShowErrorModal);
    const errorTitle = useSelector(selectModalTitle);
    const errorMessage = useSelector(selectModalMessage);
    const title = useSelector(selectPageTitle);
    const loggedIn = useSelector(isLoggedIn)

    useEffect(() => {
        if (!loggedIn){
            
        } 
    }, [loggedIn])

    const dispatch = useDispatch();

    return (
        <Container>
            { (loggedIn && window.location.pathname != '/') ?
                <SidebarAndContentContainer>
                    <Header />
                    {showErrorModal && <Modal title={errorTitle} message={errorMessage} onClose={() => dispatch(setShowErrorModal(false, "", ""))} />}
                    {loading && <Loading />}
                    <div style={{ background: 'rgb(210 207 207)', width: '100%', flexGrow: '1' }}>
                        <SectionButtonWrapper>
                            <SectionTitle>{title}</SectionTitle>
                        </SectionButtonWrapper>
                        {children}
                    </div>
                </SidebarAndContentContainer> :
                <>
                    { (window.location.pathname == '/login') ?
                        <>
                            <Header />
                            {showErrorModal && <Modal title={errorTitle} message={errorMessage} onClose={() => dispatch(setShowErrorModal(false, "", ""))} />}
                            {loading && <Loading />}
                            {children}
                        </>
                    :
                        <>{children}</>
                    }
                </>
            }
        </Container>);
};

export default MainContainer;
