import { initialState } from './initialState';
import * as types from './types';


const generalReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_LOADING:
      return {
        ...state,
        loading: action.loading
      }
    case types.SET_TITLE:
      return {
        ...state,
        pageTitle: action.pageTitle
      }
    case types.SET_PAGESTOSING:
      return {
        ...state,
        pagesToSing: action.pagesToSing
      }
    case types.SET_SHOW_ERROR_MODAL:
      return {
        ...state,
        showErrorModal: action.show,
        modalTitle: action.title,
        modalMessage: action.message,
      }
    default:
      return state;
  }
};

export default generalReducer;
