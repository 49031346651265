import React from "react";
import UserDetail from "../../commons/Users/UserDetail";
import { getUser } from "../../../store/Login/selectors";
import { useDispatch, useSelector } from "react-redux";
import { updateUser } from "../../../store/Users/actions";
import { useNavigate } from "react-router-dom";

const UserProfile = () => {
    
    const dispatch = useDispatch();
    const selectUser = useSelector(getUser);
    const navigate = useNavigate();

    const validateUser = (user) => {
        if (!user.username || !user.name || !user.dni) {
          return false;
        }
        return true;
      }
    
      const saveAction = (user) => {
        if (validateUser(user)) {
          dispatch(updateUser(user, false));
        } 
      }

    const goBack = () => {
      navigate('/home', { replace: true });
    }

    return <div>
        <h1>Perfil de Usuario</h1>
        <UserDetail userData={selectUser} goBackAction={goBack} saveAction={saveAction}/>
    </div>;
    }

export default UserProfile;