import { BasicButton } from "../../Button";
import ColumnFilter from "./columnFilter";


export const columns = (payButtonAction, getPDFAction, signatureAction, isAdmin, userId) => {

  return [
    { Header: 'Fecha', accessor: 'file_date', Filter: ColumnFilter },
    { Header: 'Orden', accessor: 'order_number', Filter: ColumnFilter },
/*     { Header: 'Fecha de alta', accessor: 'registration_date', Filter: ColumnFilter },
 */    {
      Header: 'Pago',
      accessor: 'payment',
      Filter: ColumnFilter,
      Cell: ({ row }) => (
        row.original.payment === 'No' ? (isAdmin ? (
          <BasicButton onClick={() => payButtonAction(row.original.order_number)}>Pagar</BasicButton>
        ) : 'No') : (
          row.original.payment
        )
      ),
    },
    { Header: 'Monto', accessor: 'amount', Filter: ColumnFilter },
    {
      Header: '',
      hideHeader: true,
      id: 'seePDF',
      Cell: ({ row }) => (
        isAdmin || row.original.payment === 'Si' ? (
          <BasicButton onClick={() => getPDFAction(row.original.id)}>Ver Analisis</BasicButton>
        ) : null
      ),
    },
    {
      Header: '',
      hideHeader: true,
      id: 'singPDF',
      Cell: ({ row }) => (
        (isAdmin && (userId === 199 || userId === 201 || userId === 202 || userId === 203 || userId === 18)) ? (
          <BasicButton onClick={() => signatureAction(row.original.id)}>Firmar Analisis</BasicButton>
        ) : null
      ),
    },
  ]
};


