import styled from "styled-components";
import { colors } from "../../../../../utils/colors";

export const Container = styled.div`
  height: 500px;
  display: flex;
`;

export const LeftHalf = styled.div`
  flex: 1;
`;

export const ImageContainer = styled.div`
  display: flex;
  align-items: center;

  @media (max-width: 768px) {
    flex-direction: column;
  }

`;

export const Image = styled.img`
  margin-top: 20px;
  margin-left: 30px;
  width: 200px; /* Tamaño establecido */
  height: auto; /* La altura se ajusta automáticamente según el ancho */
`;

export const DescriptionsContainer = styled.div`
  margin-left: 16px;
`;

export const DescriptionRight = styled.div`
display: flex;
flex-direction: row;
  margin-bottom: 8px;
  color: #000;
  font-family: Montserrat;
  font-size: 32px;
  font-style: italic;
  font-weight: 400;
  line-height: 26px; /* 81.25% */
  letter-spacing: 1.6px;
`;

export const DescriptionBottom = styled.div`
  font-style: italic;
  display: flex;
  margin-left: 20px;
  margin-top: 20px;
  color: #000;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 600;
  line-height: 26px; /* 162.5% */
  letter-spacing: 0.8px;
  @media (max-width: 768px) {
    margin: 0;
    justify-content: center;
  }
`;

export const Bold = styled.div`
  margin-right: 5px;
  font-weight: bold;
  color: #000;
  font-family: Montserrat;
  font-size: 16px;
  line-height: 26px; /* 162.5% */
  letter-spacing: 0.8px;
`

export const Colorize = styled.div`
  color: ${colors.logo}
`

export const Button = styled.button`
  display: inline-block;
  padding: 15px 80px;
  margin-top: 25px;
  margin-left: 20px;
  background-color: ${colors.logo};
  border: none;
  font-size: 16px;
  color: ${colors["text-white"]};
  cursor: pointer;
  @media (max-width: 768px) {
    margin-left:20%;
  }
`;

export const RightHalf = styled.div`
  flex: 1.3;
  @media (max-width: 768px) {
    display: none;
  }
`;

export const RightImageContainer = styled.div`
  padding-left: 20px;
`

export const RightImage = styled.img`
  width: 100%;
  height: 500px;
  object-fit: cover;
`;

export const Link = styled.a`
    color: ${colors["text-black"]};
    margin-left: 3px;
`