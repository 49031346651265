import UserStudiesTable from "../../commons/Users/UserStudiesTable";
import { useSelector } from "react-redux";
import { getUser } from "../../../store/Login/selectors";

const Home = () => {

  const user = useSelector(getUser);

  return (
    <div>
      <UserStudiesTable user={user}/>
    </div>
  );
}

export default Home