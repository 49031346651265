import React from "react";
import logo from "../../../../../images/gin-cast-logo.png"
import photo1 from "../../../../../images/nuevas/frente.jpg"
import WhatsAppIcon from "../Footer/Components/images/WhatsappIcon";
import { Bold, Button, Colorize, Container, DescriptionBottom, DescriptionRight, Link, DescriptionsContainer, Image, ImageContainer, LeftHalf, RightHalf, RightImage, RightImageContainer } from "./styled";

const FirstBlock = () => {

    const goToLogin = () => {
        window.location.assign("/login"); // Reemplaza "/nueva-ruta" con la URL de destino
    }

    return (
        <Container id="home">
            <LeftHalf>
                <ImageContainer>
                    <Image src={logo} alt="Descripción de la imagen" />
                    <DescriptionsContainer>
                        <DescriptionRight>
                            Laboratorio
                        </DescriptionRight>
                        <DescriptionRight>
                            <Colorize>G</Colorize>in <Colorize>C</Colorize>ast
                        </DescriptionRight>
                    </DescriptionsContainer>
                </ImageContainer>
                <DescriptionBottom>
                    Comunicate con nosotros via WhatsApp al <Link href="https://api.whatsapp.com/send?phone=5492345526042"> 2345-526042</Link>
                </DescriptionBottom>
                <DescriptionBottom>
                    Gestione sus análisis a través de nuestra nueva página web
                </DescriptionBottom>
                <Button onClick={goToLogin}> Ingresar aquí </Button>
            </LeftHalf>
            <RightHalf>
                <RightImageContainer>
                    <RightImage src={photo1} />
                </RightImageContainer>
            </RightHalf>
        </Container>
    )
}

export default FirstBlock;
