import styled from "styled-components";

export const InputComponent = styled.input`
margin-bottom: 1rem;
padding: 0.5rem;
border-radius: 5px;
background-color: transparent;
border-radius: 4px;
border: 1px solid #000;

&:focus {
  outline: none;
}
`;